import { gql } from "@apollo/client";
import { useApolloQuery } from "../useApolloQuery";

const fetchLatestActions = gql`
  query ($limit: Int, $page: Int, $countryId: Int, $themeId: Int) {
    peopleActions(
      page: $page
      limit: $limit
      countryId: $countryId
      themeId: $themeId
    ) {
      collection {
        actionDate
        description
        firstname
        lastname
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const useLatestActions = ({ variables }: any) => {
  const { data, loading } = useApolloQuery({
    queryDocument: fetchLatestActions,
    variables: variables,
  });

  return {
    data: data?.peopleActions,
    loading,
  };
};

export { useLatestActions };
