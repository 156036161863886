import React from "react";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

export * from "@mui/material/AppBar";

const AppBar: React.FC<AppBarProps> = (props) => {
  const { children, ...otherProps } = props;

  return (
    <MuiAppBar position="sticky" color="inherit" elevation={5} {...otherProps}>
      <Toolbar>{children}</Toolbar>
    </MuiAppBar>
  );
};

export { AppBar };
