import { Container, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import { usePeoplesCall } from "../../../hooks";
import PersonCard from "../../../components/PersonCard";
import { useLanguageTranslator } from "../../../components";

function Peoples() {
  const { data } = usePeoplesCall({ variables: { limit: 4 } });
  const { translator } = useLanguageTranslator();

  return (
    <Container sx={{ my: 3 }}>
      <Paper component={Container} sx={{ py: 3, mb: 2 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">{`${translator("List of")} ${translator(
            "People"
          )}`}</Typography>
          <Link href="/people/all" underline="none" color="textSecondary">
            {translator("View all")}
          </Link>
        </Stack>
      </Paper>

      <Grid container spacing={2}>
        {data?.people?.collection?.map((elem: any, i: any) => (
          <Grid key={`dashboard-peoples-${i}`} item xs={12} md={6} lg={3}>
            <PersonCard person={elem} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export { Peoples };
