import React from "react";
import { useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import { Box, Container, Stack, Paper, LinearProgress } from "@mui/material";
import { AppBar, PageTitle, SearchBar } from "../../../components";
import { PolicyEditor } from "../../../features";

import { useNotify } from "../../../components/@common/NotificationsProvider";
import { useApolloQuery } from "../../../hooks";
import { PolicyInputData } from "../../../@lib/types";

const fetchPolicyQueryDocument = gql`
  query fetchPolicyQueryDocument($policyId: ID!) {
    policy(id: $policyId) {
      id
      title
      description
      date
      link
      country {
        id
      }
      theme {
        id
      }
      geographicalArea {
        id
      }
      policyCategory {
        id
      }
    }
  }
`;

const updatePolicyMutationDocument = gql`
  mutation updatePolicyMutation($policyUpdateInput: PolicyUpdateInput!) {
    policyUpdate(input: $policyUpdateInput) {
      policy {
        id
      }
    }
  }
`;

const PolicyEdit: React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const params = useParams();

  const { data, loading } = useApolloQuery({
    queryDocument: fetchPolicyQueryDocument,
    variables: { policyId: params.policyId },
  });

  const [updatePolicy, { loading: updating }] = useMutation(
    updatePolicyMutationDocument
  );

  const initialValues: PolicyInputData | undefined = useMemo(() => {
    if (!data?.policy) return undefined;

    // Destructure first to get rid of values not accepted as of type `PolicyInput`.
    const {
      id,
      country,
      theme,
      geographicalArea,
      policyCategory,
      ...otherFields
    } = data.policy;

    // Re-structure and return `PolicyInput` type object.
    return {
      ...otherFields,
      countryId: country.id,
      geographicalAreaId: geographicalArea.id,
      themeId: theme.id,
      policyCategoryId: policyCategory.id,
    };
  }, [data]);

  /**
   * handleFinish()
   */
  const handleFinish = useCallback(
    async (data: PolicyInputData) => {
      try {
        await updatePolicy({
          variables: {
            policyUpdateInput: {
              id: params.policyId,
              policyInput: data,
            },
          },
        });

        navigate(`/policies/${params.policyId}`, { replace: true });
      } catch (err) {
        notify.error(err as Error);
      }
    },
    [updatePolicy, navigate, params, notify]
  );

  /**
   * handleCancel()
   */
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box mb={24}>
      <AppBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
          flexGrow={1}
        >
          <PageTitle title="Edit policy" />

          <SearchBar placeholder="Search tree and keyword" />
        </Stack>
        {loading && (
          <LinearProgress
            sx={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
          />
        )}
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3 }}>
          <PolicyEditor
            initialValues={initialValues}
            disabled={loading || updating}
            onFinish={handleFinish}
            onCancel={handleCancel}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export { PolicyEdit };
