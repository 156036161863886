import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import placeholderImage from "../../../../@lib/images/person-placeholder.jpg";
import { useAuthSession } from "../../../../components/AuthSessionProvider";
import { ConditionalRenderer } from "../../../../components";

function PeopleListCard({ elem, isPrivate }: any) {
  const { user } = useAuthSession();
  const isLoggedIn = !!user;
  return (
    <Card sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <CardActionArea
        component={RouterLink}
        to={`/personDetail?id=${elem.id}`}
        sx={{ flexBasis: 150, maxWidth: 150 }}
      >
        <CardMedia
          component="img"
          sx={{ flexBasis: 150, maxWidth: 150, p: 2, borderRadius: 4 }}
          height={150}
          image={elem.picture || placeholderImage}
          alt={`${elem.firstname}'s picture`}
        />
      </CardActionArea>
      <CardContent
        component={Stack}
        sx={{ flexGrow: 1 }}
        justifyContent="space-between"
        alignItems="flex-start"
        gap={2}
      >
        <div>
          <Typography variant="h6">
            {elem.firstname} {elem.lastname}
          </Typography>
          <Typography variant="body2">Country</Typography>
          <Typography component="p" variant="caption">
            {elem.title}
          </Typography>
        </div>
      </CardContent>
      <ConditionalRenderer
        condition={
          isPrivate && isLoggedIn && elem?.status?.toLowerCase() !== "approved"
        }
      >
        <Stack flexBasis="20%" gap={2}>
          <Button size="small" variant="contained">
            EDIT
          </Button>
          <Button size="small" variant="contained">
            DELETE
          </Button>
        </Stack>
      </ConditionalRenderer>
    </Card>
  );
}

export { PeopleListCard };
