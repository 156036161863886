import { useMemo } from "react";
import { gql } from "@apollo/client";
import { Practice } from "../../@lib/types";
import { useApolloQuery } from "../useApolloQuery";

export type PracticesCollectionData = {
  practices: { collection: Practice[]; metadata: {} };
};

const fetchPracticesQueryDocument = gql`
  query FetchPracticesQuery(
    $limit: Int
    $page: Int
    $countryId: Int
    $themeId: Int
    $startYear: Int
    $endYear: Int
  ) {
    practices(
      limit: $limit
      page: $page
      countryId: $countryId
      themeId: $themeId
      startYear: $startYear
      endYear: $endYear
    ) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        id
        link
        picture
        practiceCategory {
          name
        }
        status
        theme {
          id
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const practicesCategoryQueryDocument = gql`
  query GetPracticeCategories {
    practiceCategories {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const usePublicPracticesCall = ({ variables, currTabIndex }: any) => {
  const { data, loading, refetch } = useApolloQuery({
    queryDocument: fetchPracticesQueryDocument,
    variables: variables,
  });

  const { data: category } = useApolloQuery({
    queryDocument: practicesCategoryQueryDocument,
  });


  const dist = useMemo(() => {
    if (!category?.practiceCategories || !data?.practices?.collection)
      return {};

    const dynamicDist: Record<string, any> = {};

    category.practiceCategories.forEach((cat: any) => {
      const categoryName = cat.name.toLowerCase();
      dynamicDist[categoryName] = [];
    });

    dynamicDist.other = [];

    data?.practices?.collection?.forEach((p: any) => {
      const practiceCategoryName = p?.practiceCategory?.name?.toLowerCase();
      if (practiceCategoryName && dynamicDist[practiceCategoryName]) {
        dynamicDist[practiceCategoryName].push(p);
      } else {
        dynamicDist.other.push(p);
      }
    });

    return dynamicDist;
  }, [category?.practiceCategories, data?.practices?.collection]);

  const publicTabs = useMemo(() => {
    if (!dist) return [];

    return Object.entries(dist).map(([key, value]) => ({
      value: key,
      count: value.length,
      title:
        key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1"),
    }));
  }, [dist]);

  const modifiedPublicTabs = [
    {
      count: data?.practices?.collection?.length,
      title: "All",
      value: "all",
    },
    ...(publicTabs || []),
  ];

  const filteredDataResults = useMemo(() => {
    if (currTabIndex === "all" || !currTabIndex)
      return data?.practices?.collection;
    return data?.practices?.collection?.filter(
      (item: any) =>
        item?.practiceCategory?.name?.toLowerCase() === currTabIndex
    );
  }, [data?.practices?.collection, currTabIndex]);

  return {
    practices: filteredDataResults || [],
    tabs: modifiedPublicTabs || [],
    loading,
    metadata: data?.practices?.metadata,
  };
};

export { usePublicPracticesCall };
