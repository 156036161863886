import type { OptionType } from "../../@lib/types";
import { gql } from "@apollo/client";
import { useApolloQuery } from "../useApolloQuery";

const themesQueryDocument = gql`
  query Themes {
    themes {
      label: title
      value: id
    }
  }
`;

const useThemeOptions = (): [OptionType[], boolean] => {
  const { data, loading } = useApolloQuery({
    queryDocument: themesQueryDocument,
  });

  return [data?.themes || [], loading];
};

export { useThemeOptions };
