import { useState } from "react";
import { Fade, Slide, Grid, Container, Box } from "@mui/material";
import type { Practice } from "../../../@lib/types";
import {
  Card,
  CardSkeleton,
  Filters,
  Header,
  LatestReport,
  TabsFilters,
  useLanguageTranslator,
} from "../../../components";
import { usePracticesDocumentsCall, usePublicPracticesCall } from "../../../hooks";

export type PracticesCollectionData = {
  practices: Practice[];
};

function PublicPractices() {
  const [filters, setFilters] = useState({
    country: "",
    startYear: null,
    endYear: null,
    theme: "",
  });
  const [currTabIndex, setCurrTabIndex] = useState("all");

  const { translator } = useLanguageTranslator();

  const {
    practices = [],
    tabs,
    loading,
  } = usePublicPracticesCall({
    variables: {
      limit: 8,
      ...(filters?.country ? { countryId: Number(filters?.country) } : {}),
      ...(filters?.theme ? { themeId: Number(filters?.theme) } : {}),
      ...(filters?.startYear ? { startYear: Number(filters?.startYear) } : {}),
      ...(filters?.endYear ? { endYear: Number(filters?.endYear) } : {}),
    },
    currTabIndex: currTabIndex,
  });
  const { practiceDocuments } = usePracticesDocumentsCall({
    variables: { limit: 8 },
  }) as any;

  return (
    <Box pb={4}>
      <Header title="Practices" subtitle="Lorem ipsum dolor sit amet." />
      <Filters setFilters={setFilters} filters={filters} />
      <Container sx={{ my: 3 }}>
        <TabsFilters
          currTab={currTabIndex}
          setCurrTab={setCurrTabIndex}
          listItems={tabs}
          heading={`${translator("List of")} ${translator("Practices")}`}
        />

        <Grid container spacing={2}>
          {practices?.map((p: { id: number }, i: number) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card
                  {...p}
                  path={`/practices/${p?.id}`}
                  disabled={loading}
                  sx={{ height: "100%" }}
                  isPrivate={false}
                />
              </Slide>
            </Grid>
          ))}

          {loading &&
            practices.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {CardSkeleton}
                  </Grid>
                </Fade>
              ))}
        </Grid>
      </Container>

      <LatestReport
        items={practiceDocuments}
        path="/practices/viewAllReports"
      />
    </Box>
  );
}

export { PublicPractices };
