import type { ForwardRefRenderFunction } from "react";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

import { forwardRef } from "react";
import { gql } from "@apollo/client";
import { useApolloQuery } from "../../hooks";

export type ActionsDropdownProps = SelectProps & {};

const actionsQueryDocument = gql`
  query Actions {
    actions {
      label: description
      value: id
    }
  }
`;

const ActionsDropdownComp: ForwardRefRenderFunction<
  HTMLElement,
  ActionsDropdownProps
> = (props, ref) => {
  const { children, ...selectProps } = props;

  const { data, loading, refetch } = useApolloQuery({
    queryDocument: actionsQueryDocument,
  });

  return (
    <Select
      ref={ref}
      displayEmpty={!loading}
      startAdornment={
        loading && (
          <InputAdornment position="start">
            <CircularProgress size={20} />
          </InputAdornment>
        )
      }
      {...selectProps}
    >
      <MenuItem value="" disabled>
        Select action
      </MenuItem>
      {data?.actions.map((op: { value: string; label: string }, i: number) => (
        <MenuItem key={`${op.value}-${i}`} value={op.value}>
          {op.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const ActionsDropdown = forwardRef(ActionsDropdownComp);

export { ActionsDropdown };
