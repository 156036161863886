import { useState } from "react";
import {
  FormControl,
  Grid,
  FormLabel,
  OutlinedInput,
  Select,
  MenuItem,
  Divider,
  Stack,
  Button,
} from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { FranceIcon, PortugueseIcon } from "../../@lib/icons";
import * as authStore from "../../@lib/services/storage/auth";

const updateUserDetailsMutationQuery = gql`
  mutation UpdateUser($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      clientMutationId
      user {
        companyName
        email
        firstName
        id
        isConfirmed
        isLocked
        lang
        lastName
      }
    }
  }
`;

function PersonalDetailsForm({ user }: { user: any }) {
  const [formData, setFormData] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    lang: user?.lang,
  });

  const [updateUser, { loading }] = useMutation(
    updateUserDetailsMutationQuery,
    {
      context: {
        headers: {
          "access-token": user?.accessToken,
          client: user?.client,
          uid: user?.email,
        },
      },
    }
  );

  function handleChange(e: any) {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit() {
    try {
      const response = await updateUser({
        variables: {
          input: {
            userDetailsInput: {
              email: formData.email,
              firstName: formData.firstName,
              lastName: formData.lastName,
              lang: formData?.lang,
            },
          },
        },
      });

      if (response?.data?.userUpdate?.user) {
        await Promise.all([
          authStore.setUser({
            ...user,
            email: response?.data?.userUpdate?.user?.email,
            firstName: response?.data?.userUpdate?.user?.firstName,
            lastName: response?.data?.userUpdate?.user?.lastName,
            lang: response?.data?.userUpdate?.user?.lang,
          }),
        ]);

        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (err) {
      console.error("Error during mutation:", err);
    }
  }

  return (
    <>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
            <FormControl fullWidth>
              <FormLabel>First Name</FormLabel>
              <OutlinedInput
                name="firstName"
                value={formData?.firstName}
                sx={{ border: "1px solid", borderColor: "black" }}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <FormControl fullWidth>
              <FormLabel>Last name</FormLabel>
              <OutlinedInput
                name="lastName"
                value={formData?.lastName}
                sx={{ border: "1px solid", borderColor: "black" }}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <FormControl fullWidth>
              <FormLabel>Email</FormLabel>
              <OutlinedInput
                name="email"
                value={formData?.email}
                sx={{ border: "1px solid", borderColor: "black" }}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <FormControl fullWidth>
              <FormLabel>Preferred language</FormLabel>
              <Select
                name="lang"
                sx={{ border: "1px solid", borderColor: "black" }}
                value={formData?.lang}
                onChange={handleChange}
              >
                <MenuItem value={"en"}>
                  <div style={{ display: "flex", alignItems: "itemsCenter" }}>
                    <div style={{ marginRight: "10px" }}>
                      <img
                        src="/assets/images/en.png"
                        alt="logo"
                        width="20"
                        height="20"
                      />
                    </div>
                    English
                  </div>
                </MenuItem>
                <MenuItem value={"fr"}>
                  <div style={{ display: "flex", alignItems: "itemsCenter" }}>
                    <div style={{ marginRight: "10px" }}>
                      <FranceIcon />
                    </div>
                    French
                  </div>
                </MenuItem>
                <MenuItem value={"pt"}>
                  <div style={{ display: "flex", alignItems: "itemsCenter" }}>
                    <div style={{ marginRight: "10px" }}>
                      <PortugueseIcon />
                    </div>
                    Portuguese
                  </div>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <Divider sx={{ my: 2 }} />
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <Button size="large" variant="contained" color="inherit" sx={{ px: 2 }}>
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          sx={{ px: 2 }}
          onClick={handleSubmit}
        >
          Update details
        </Button>
      </Stack>
    </>
  );
}

export default PersonalDetailsForm;
