import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  ConditionalRenderer,
  Header,
  useLanguageTranslator,
} from "../../components";
import { useLocation } from "react-router-dom";
import { SubmissionTable } from "../adminView/table";
import { gql } from "@apollo/client";
import { useApolloQuery } from "../../hooks";
import { DownArrowIcon } from "../../@lib/icons";
import { useState } from "react";

const policyDocument = gql`
  query ($limit: Int, $page: Int) {
    pendingPolicies(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        geographicalArea {
          name
        }
        id
        link
        picture
        policyCategory {
          name
        }
        status
        theme {
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const pendingPracticeDocument = gql`
  query ($limit: Int, $page: Int) {
    pendingPractices(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        id
        link
        picture
        policy {
          title
        }
        practiceCategory {
          name
        }
        practiceType {
          name
        }
        status
        theme {
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const pendingPeopleDocument = gql`
  query ($limit: Int, $page: Int) {
    pendingPeople(limit: $limit, page: $page) {
      collection {
        country
        createdAt
        firstname
        id
        lastname
        picture
        status
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

function isModule(location: any) {
  const isPractices = location?.pathname?.includes("practices") || false;
  const isPolicies = location?.pathname?.includes("policies") || false;
  if (isPolicies) {
    return {
      queryDocument: policyDocument,
      createPath: "/admin/policies/new",
    };
  } else if (isPractices) {
    return {
      queryDocument: pendingPracticeDocument,
      createPath: "/admin/practices/new",
    };
  } else {
    return {
      queryDocument: pendingPeopleDocument,
      createPath: "/admin/people/new",
    };
  }
}

function AdminSubmissionALL() {
  const [limit, setLimit] = useState(8);

  const location = useLocation();
  const { translator } = useLanguageTranslator();

  const { queryDocument, createPath }: any = isModule(location);

  const { data: pending } = useApolloQuery({
    queryDocument: queryDocument,
    isPrivateCall: true,
    variables: {
      limit: limit,
    },
  });

  function onClickHandler() {
    setLimit(limit + 8);
  }

  const metadata =
    pending?.pendingPolicies?.metadata ||
    pending?.pendingPractices?.metadata ||
    pending?.pendingPeople?.metadata;

  const hasMore =
    limit === metadata?.totalCount ||
    limit > metadata?.totalCount ||
    !metadata?.totalCount;

  return (
    <>
      <Header
        title="Admin Area"
        subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX"
      />
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3 }}>
          <Stack
            direction={{ lg: "row" }}
            gap={2}
            justifyContent={{ lg: "space-between" }}
            alignItems={{ lg: "center" }}
          >
            <Box>
              <Typography variant="h4">
                {translator("Students Submissions")}
              </Typography>
            </Box>
            <Box>
              <Button
                href={createPath}
                sx={{
                  px: 6,
                  backgroundColor: "#F6A000",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "primary.light",
                  },
                }}
              >
                {translator("generic.CREATE")}
              </Button>
            </Box>
          </Stack>
          <SubmissionTable
            data={
              pending?.pendingPolicies?.collection ||
              pending?.pendingPractices?.collection ||
              pending?.pendingPeople?.collection ||
              []
            }
            isAllShow
          />
          <ConditionalRenderer condition={!hasMore}>
            <div
              style={{
                margin: "10px 0px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button style={{ color: "#979da7" }} onClick={onClickHandler}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  Show more <DownArrowIcon />
                </div>
              </Button>
            </div>
          </ConditionalRenderer>
        </Paper>
      </Container>
    </>
  );
}

export { AdminSubmissionALL };
