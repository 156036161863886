import { Box, Button, Paper } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDataCall } from "./dataCall";
import { useState } from "react";
import { ConditionalRenderer, LatestReport, Header, Filters } from "../../components";


function AllReports() {
  const [filters, setFilters] = useState({
    country: "",
    startYear: "",
    endYear: "",
    theme: "",
  });
  const [pageNumber, setPageNumber] = useState(1);

  const { module } = useParams();
  const { data } = useDataCall({
    module: module,
    variables: {
      limit: 8,
      page: pageNumber,
    },
  }) as any;

  function onClickHandler() {
    if (
      module === "policies"
        ? pageNumber === data?.policyDocuments?.metadata?.totalPages
        : pageNumber === data?.practiceDocuments?.metadata?.totalPages
    )
      return;
    setPageNumber(pageNumber + 1);
  }

  const hasMore =
    module === "policies"
      ? pageNumber === data?.policyDocuments?.metadata?.totalPages ||
        !data?.policyDocuments?.metadata?.totalPages
      : pageNumber === data?.practiceDocuments?.metadata?.totalPages ||
        !data?.practiceDocuments?.metadata?.totalPages;

  return (
    <Box pb={3}>
      <Header
        title="Reports"
        subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX."
      />
      <Filters setFilters={setFilters} filters={filters} />

      <LatestReport
        isAllShow={false}
        items={
          data?.policyDocuments?.collection ||
          data?.practiceDocuments?.collection ||
          []
        }
        path={`${
          data?.policyDocuments?.collection
            ? "/policies/viewAllReports"
            : "/practices/viewAllReports"
        }`}
      />
      <ConditionalRenderer condition={!hasMore}>
        <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
          <Button onClick={onClickHandler}>Show more</Button>
        </Paper>
      </ConditionalRenderer>
    </Box>
  );
}

export { AllReports };
