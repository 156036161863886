function PortugueseIcon() {
  return (
    <svg
      height="20px"
      width="20px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 496 496"
      xmlSpace="preserve"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          style={{ fill: "#006600" }}
          d="M162.4,77.6H48c-26.4,0-48,21.6-48,48.8v243.2c0,27.2,21.6,48.8,48,48.8h115.2V77.6H162.4z"
        ></path>{" "}
        <path
          style={{ fill: "#FF0000" }}
          d="M448,77.6H333.6H162.4v340.8h170.4H448c26.4,0,48-21.6,48-48.8V126.4C496,99.2,474.4,77.6,448,77.6z"
        ></path>{" "}
        <path
          style={{ fill: "#E20000" }}
          d="M448,77.6H333.6H162.4v97.6L332.8,320L448,418.4c26.4,0,48-21.6,48-48.8V126.4 C496,99.2,474.4,77.6,448,77.6z"
        ></path>{" "}
        <polygon
          style={{ fill: "#004F00" }}
          points="162.4,77.6 48,77.6 162.4,175.2 "
        ></polygon>{" "}
        <path
          style={{ fill: "#CE0000" }}
          d="M333.6,369.6H162.4v48.8h170.4H448c26.4,0,48-21.6,48-48.8H333.6z"
        ></path>{" "}
        <path
          style={{ fill: "#013F01" }}
          d="M0,369.6c0,27.2,21.6,48.8,48,48.8h115.2v-48.8H0z"
        ></path>{" "}
        <path
          style={{ fill: "#CE0000" }}
          d="M448,77.6H333.6H162.4v35.2l170.4,52L496,214.4v-88C496,99.2,474.4,77.6,448,77.6z"
        ></path>{" "}
        <polygon
          style={{ fill: "#013F01" }}
          points="162.4,77.6 48,77.6 162.4,112.8 "
        ></polygon>{" "}
        <circle
          style={{ fill: "#FFFFFF" }}
          cx="163.2"
          cy="243.2"
          r="68.8"
        ></circle>{" "}
        <path
          style={{ fill: "#F2EFCE" }}
          d="M163.2,173.6c38.4,0,68.8,31.2,68.8,68.8c0,38.4-31.2,68.8-68.8,68.8"
        ></path>{" "}
        <path
          style={{ fill: "#C60606" }}
          d="M116.8,256.8L116.8,256.8c0,12,4.8,23.2,13.6,32c8,8,19.2,13.6,32,13.6c12,0,24-4.8,32-12.8 s13.6-19.2,13.6-32l0,0v-60.8h-90.4L116.8,256.8 M188.8,214.4v38.4v4l0,0c0,0.8,0,2.4,0,3.2c-0.8,6.4-3.2,11.2-8,15.2 c-4.8,4.8-12,8-19.2,8s-13.6-3.2-19.2-8c-4.8-4.8-8-12-8-19.2v-42.4L188.8,214.4"
        ></path>{" "}
        <path
          style={{ fill: "#FFFF00" }}
          d="M116.8,256.8L116.8,256.8L116.8,256.8c0,12,4.8,23.2,13.6,32c8,8,19.2,13.6,32,13.6v-17.6l0,0 c-7.2,0-13.6-3.2-19.2-8c-4.8-4.8-8-12-8-19.2v-42.4h27.2v-18.4h-44.8L116.8,256.8z"
        ></path>{" "}
        <g>
          {" "}
          <path
            style={{ fill: "#1477EF" }}
            d="M156.8,249.6L156.8,249.6L156.8,249.6c0,1.6,0.8,3.2,1.6,4.8c0.8,0.8,2.4,1.6,4,1.6s3.2-0.8,4-1.6 c0.8-0.8,1.6-2.4,1.6-4.8v-8.8h-12L156.8,249.6z"
          ></path>{" "}
          <path
            style={{ fill: "#1477EF" }}
            d="M156.8,270.4L156.8,270.4L156.8,270.4c0,1.6,0.8,3.2,1.6,4.8c0.8,0.8,2.4,1.6,4,1.6s3.2-0.8,4-1.6 c1.6-1.6,1.6-3.2,1.6-4.8v-8.8h-12L156.8,270.4z"
          ></path>{" "}
          <path
            style={{ fill: "#1477EF" }}
            d="M140,249.6L140,249.6c0,1.6,0.8,3.2,1.6,4.8c0.8,0.8,2.4,1.6,4,1.6s3.2-0.8,4-1.6 c0.8-0.8,1.6-2.4,1.6-4.8v-8.8h-12L140,249.6z"
          ></path>{" "}
        </g>{" "}
        <path
          style={{ fill: "#005CB7" }}
          d="M184.8,249.6v-8.8h-12v8.8l0,0l0,0c0,1.6,0.8,3.2,1.6,4.8c0.8,0.8,2.4,1.6,4,1.6s3.2-0.8,4-1.6 S184.8,252,184.8,249.6z"
        ></path>{" "}
        <path
          style={{ fill: "#1477EF" }}
          d="M158.4,233.6c0.8,0.8,2.4,1.6,4,1.6s3.2-0.8,4-1.6s1.6-2.4,1.6-4.8V220h-12v8.8l0,0l0,0 C156.8,231.2,157.6,232.8,158.4,233.6z"
        ></path>{" "}
        <g>
          {" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M119.2,212.8h13.6c0,0,0.8,0,0.8-0.8c0,0,0-0.8-0.8-0.8h-3.2l0,0h3.2l0,0l0,0l-0.8-4.8l0,0l0,0 c0,0,0.8,0,0.8-0.8c0,0,0-0.8-0.8-0.8l0,0h-12c0,0-0.8,0-0.8,0.8c0,0,0,0.8,0.8,0.8l0,0v4.8l0,0l0,0h3.2l0,0H120 C119.2,211.2,119.2,212,119.2,212.8L119.2,212.8z M124,208c-0.8,0.8-0.8,1.6-0.8,2.4C123.2,209.6,124,208.8,124,208z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M124.8,199.2h3.2c0,0,0.8,0,0.8-0.8v-1.6l0,0l0,0H128v0.8h-0.8v-0.8l0,0l0,0h-0.8l0,0l0,0v0.8h-0.8 v-0.8h-0.8l0,0l0,0l0,0l0,0l0,0L124.8,199.2C124,199.2,124,199.2,124.8,199.2z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M168.8,211.2h-3.2l0,0H168.8L168.8,211.2L168.8,211.2l-0.8-4.8l0,0l0,0c0,0,0.8,0,0.8-0.8 c0,0,0-0.8-0.8-0.8l0,0h-12c0,0-0.8,0-0.8,0.8c0,0,0,0.8,0.8,0.8l0,0l-0.8,4.8l0,0l0,0h3.2l0,0h-3.2c0,0-0.8,0-0.8,0.8 c0,0,0,0.8,0.8,0.8h13.6C169.6,212.8,169.6,212.8,168.8,211.2C169.6,212,169.6,211.2,168.8,211.2z M159.2,210.4 c0-0.8,0.8-1.6,0.8-2.4C160,208.8,159.2,209.6,159.2,210.4z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M120,205.6h12.8c0,0,0.8,0,0.8-0.8l0,0l0,0c0,0,0-0.8-0.8-0.8H120 C119.2,204.8,119.2,204.8,120,205.6L120,205.6L120,205.6C119.2,205.6,119.2,205.6,120,205.6z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="128,199.2 124.8,199.2 124.8,199.2 124.8,199.2 124.8,204 124.8,204.8 124.8,204.8 128,204.8 128,204.8 128,199.2 "
          ></polygon>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="128.8,201.6 128.8,201.6 128.8,204.8 128.8,204.8 132,204.8 132,201.6 132,201.6 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M129.6,200H128l0,0l0,0v1.6l0,0c0,0,0,0.8,0.8,0.8h3.2c0,0,0.8,0,0.8-0.8l0,0l0,0V200H132l0,0l0,0 l0,0h-0.8l0,0H129.6L129.6,200L129.6,200L129.6,200L129.6,200L129.6,200L129.6,200z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M120,201.6L120,201.6L120,201.6h4c0,0,0.8,0,0.8-0.8V200H124l0,0h-0.8l0,0l0,0h-0.8l0,0l0,0h-0.8 l0,0H120l0,0l0,0V201.6L120,201.6z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="120.8,204.8 120.8,204.8 124,204.8 123.2,201.6 120.8,201.6 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M156,205.6h12.8c0,0,0.8,0,0.8-0.8l0,0l0,0c0,0,0-0.8-0.8-0.8H156C156,204.8,155.2,204.8,156,205.6 L156,205.6L156,205.6C155.2,205.6,156,205.6,156,205.6z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M160.8,199.2h3.2c0,0,0.8,0,0.8-0.8v-1.6l0,0l0,0H164v0.8h-0.8v-0.8l0,0l0,0h-0.8l0,0l0,0v0.8h-0.8 v-0.8H160l0,0l0,0l0,0l0,0l0,0L160.8,199.2C160,199.2,160.8,199.2,160.8,199.2z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="164,199.2 160.8,199.2 160.8,204.8 160.8,204.8 164,204.8 164,204.8 164,199.2 "
          ></polygon>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="164.8,201.6 164.8,201.6 164.8,204.8 164.8,204.8 168,204.8 168,201.6 168,201.6 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M165.6,200h-0.8l0,0l0,0v1.6l0,0c0,0,0,0.8,0.8,0.8h2.4c0,0,0.8,0,0.8-0.8l0,0l0,0V200H168l0,0l0,0 l0,0h-0.8l0,0H165.6L165.6,200L165.6,200L165.6,200L165.6,200L165.6,200L165.6,200z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M156.8,201.6h3.2c0,0,0.8,0,0.8-0.8V200H160l0,0h-0.8l0,0l0,0h-0.8l0,0l0,0h-0.8l0,0h-0.8l0,0 L156.8,201.6C156,201.6,156,201.6,156.8,201.6z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="156.8,201.6 156.8,204.8 160,204.8 160,201.6 "
          ></polygon>{" "}
        </g>{" "}
        <g>
          {" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M191.2,212.8h13.6c0,0,0.8,0,0.8-0.8c0,0,0-0.8-0.8-0.8h-3.2l0,0h3.2l0,0l0,0l-0.8-4.8l0,0l0,0 c0,0,0.8,0,0.8-0.8c0,0,0-0.8-0.8-0.8l0,0h-12c0,0-0.8,0-0.8,0.8c0,0,0,0.8,0.8,0.8l0,0v4.8l0,0l0,0h3.2l0,0H192 C191.2,211.2,191.2,212,191.2,212.8L191.2,212.8z M196,208c-0.8,0.8-0.8,1.6-0.8,2.4C195.2,209.6,196,208.8,196,208z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M192,205.6h12.8c0,0,0.8,0,0.8-0.8l0,0l0,0c0,0,0-0.8-0.8-0.8H192 C191.2,204.8,191.2,204.8,192,205.6L192,205.6L192,205.6C191.2,205.6,191.2,205.6,192,205.6z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M196.8,199.2h3.2c0,0,0.8,0,0.8-0.8v-1.6l0,0l0,0H200v0.8h-0.8v-0.8l0,0l0,0h-0.8l0,0l0,0v0.8h-0.8 v-0.8h-0.8l0,0l0,0l0,0l0,0l0,0L196.8,199.2C196,199.2,196,199.2,196.8,199.2z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="200,199.2 196.8,199.2 196.8,204.8 196.8,204.8 200,204.8 200,204.8 200,199.2 "
          ></polygon>{" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="200.8,201.6 200.8,201.6 200.8,204.8 200.8,204.8 204,204.8 204,201.6 204,201.6 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M200.8,200H200l0,0l0,0v1.6l0,0c0,0,0,0.8,0.8,0.8h3.2c0,0,0.8,0,0.8-0.8l0,0l0,0V200H204l0,0l0,0 l0,0h-0.8l0,0H200.8L200.8,200L200.8,200L200.8,200L200.8,200L200.8,200L200.8,200z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M192,201.6L192,201.6L192,201.6h4c0,0,0.8,0,0.8-0.8v-1.6H196v0.8h-0.8l0,0l0,0h-0.8l0,0l0,0h-0.8 l0,0H192l0,0l0,0V201.6L192,201.6z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="192.8,201.6 192,204.8 192,204.8 195.2,204.8 195.2,201.6 "
          ></polygon>{" "}
        </g>{" "}
        <g>
          {" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M133.6,248.8C133.6,248.8,133.6,248,133.6,248.8l-4-0.8l0,0L133.6,248.8L133.6,248.8L133.6,248.8 l-1.6-5.6l0,0l0,0c0,0,0.8,0,0.8-0.8s0-0.8-0.8-0.8l0,0h-12c0,0-0.8,0-0.8,0.8s0,0.8,0.8,0.8l0,0v4.8l0,0l0,0h3.2l0,0H120 c0,0-0.8,0-0.8,0.8c0,0,0,0.8,0.8,0.8L133.6,248.8C133.6,249.6,133.6,248.8,133.6,248.8z M123.2,247.2c0-0.8,0.8-1.6,0.8-2.4 C124,245.6,123.2,246.4,123.2,247.2z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M132.8,240.8H120c0,0-0.8,0-0.8,0.8l0,0l0,0c0,0,0,0.8,0.8,0.8h12.8 C132.8,242.4,132.8,241.6,132.8,240.8L132.8,240.8L132.8,240.8C132.8,241.6,132.8,240.8,132.8,240.8z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M124,233.6L124,233.6L124,233.6L124,233.6L124,233.6L124,233.6v1.6c0,0,0,0.8,0.8,0.8h3.2 c0,0,0.8,0,0.8-0.8v-1.6l0,0l0,0H128v0.8h-0.8v-0.8l0,0l0,0h-0.8l0,0l0,0v0.8h-0.8v-0.8H124z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="128,236 124.8,236 124.8,236 124.8,236 124.8,240.8 124.8,240.8 124.8,240.8 128,240.8 128,240.8 128,236 "
          ></polygon>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="132,238.4 128.8,238.4 128.8,238.4 128.8,240.8 128.8,240.8 132,240.8 132,238.4 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M129.6,236h-0.8l0,0l0,0v1.6l0,0c0,0,0,0.8,0.8,0.8h3.2c0,0,0.8,0,0.8-0.8l0,0l0,0V236h-0.8l0,0l0,0 v0.8H132V236H129.6L129.6,236L129.6,236L129.6,236L129.6,236L129.6,236L129.6,236z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M120,236L120,236L120,236v1.6l0,0l0,0l0,0l0,0h3.2c0,0,0.8,0,0.8-0.8v-1.6h-0.8v0.8h-0.8v-0.8h-0.8 v0.8h-0.8v-0.8H120V236z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="120.8,238.4 120.8,240.8 120.8,240.8 124,240.8 123.2,238.4 123.2,238.4 "
          ></polygon>{" "}
        </g>{" "}
        <g>
          {" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="195.2,280 194.4,279.2 192,280.8 194.4,283.2 196,281.6 196,281.6 196,281.6 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M185.6,274.4L185.6,274.4L185.6,274.4L185.6,274.4c-0.8,0-0.8,0.8-0.8,0.8l8.8,8.8h0.8l0,0l0,0v-0.8 L185.6,274.4z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M193.6,284l-8.8-8.8H184v0.8l0,0l-4,3.2l0,0l0,0l2.4,2.4l0,0l-2.4-2.4h-0.8v0.8l9.6,9.6h0.8v-0.8 l-2.4-2.4l0,0l2.4,2.4l0,0l0,0l3.2-4L193.6,284C193.6,285.6,193.6,285.6,193.6,284C193.6,284.8,193.6,284.8,193.6,284L193.6,284z M184,280.8c0.8-0.8,1.6-0.8,2.4-0.8C185.6,280,184.8,280.8,184,280.8z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M192.8,273.6C192.8,273.6,192.8,274.4,192.8,273.6l2.4,3.2h0.8l0.8-0.8l0,0l0,0l-0.8-0.8l-0.8,0.8 l-0.8-0.8l0.8-0.8l0,0l0,0l-0.8-0.8l0,0l0,0l-0.8,0.8L192.8,273.6l0.8-0.8l-0.8-0.8l0,0l0,0l0,0l0,0L192.8,273.6z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="188.8,277.6 191.2,280 191.2,280 195.2,276.8 195.2,276.8 195.2,276.8 192.8,274.4 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M197.6,279.2L197.6,279.2L197.6,279.2h-0.8H197.6l-0.8-0.8l0,0l-0.8,0.8l-0.8-0.8l0.8-0.8l-0.8-0.8 l0,0l0,0l0,0l-0.8,0.8l0,0v0.8l2.4,2.4h0.8l0,0l0,0L197.6,279.2L197.6,279.2L197.6,279.2z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M190.4,275.2L190.4,275.2L190.4,275.2C191.2,275.2,191.2,275.2,190.4,275.2l1.6-0.8l-0.8-0.8 l-0.8,0.8l-0.8-0.8l0.8-0.8l-0.8-0.8l-0.8,0.8L188,272l0.8-0.8l-0.8-0.8l0,0l0,0l0,1.6v0.8L190.4,275.2z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="188.8,277.6 190.4,275.2 190.4,275.2 188,272.8 186.4,275.2 186.4,275.2 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M204.8,248h-3.2l0,0H204.8L204.8,248L204.8,248l-0.8-4.8l0,0l0,0c0,0,0.8,0,0.8-0.8s0-0.8-0.8-0.8 l0,0h-12c0,0-0.8,0-0.8,0.8s0,0.8,0.8,0.8l0,0v4.8l0,0l0,0h3.2l0,0H192c0,0-0.8,0-0.8,0.8c0,0,0,0.8,0.8,0.8h13.6 C204.8,249.6,205.6,248.8,204.8,248C205.6,248.8,204.8,248,204.8,248z M195.2,247.2c0-0.8,0.8-1.6,0.8-2.4 C195.2,245.6,195.2,246.4,195.2,247.2z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M204.8,240.8H192c0,0-0.8,0-0.8,0.8l0,0l0,0c0,0,0,0.8,0.8,0.8h12.8 C204.8,242.4,204.8,241.6,204.8,240.8C204.8,241.6,204.8,241.6,204.8,240.8L204.8,240.8C204.8,241.6,204.8,240.8,204.8,240.8z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M196,233.6L196,233.6L196,233.6v1.6c0,0,0,0.8,0.8,0.8h3.2c0,0,0.8,0,0.8-0.8v-1.6l0,0l0,0H200v0.8 h-0.8v-0.8l0,0l0,0h-0.8l0,0l0,0v0.8h-0.8v-0.8H196L196,233.6L196,233.6L196,233.6z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="199.2,236 196.8,236 196,240.8 196,240.8 200,240.8 200,240.8 199.2,236 "
          ></polygon>{" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="204,238.4 200.8,238.4 200.8,238.4 200.8,240.8 200.8,240.8 204,240.8 204,238.4 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M200.8,236H200l0,0l0,0v0.8v0.8c0,0,0,0.8,0.8,0.8h3.2c0,0,0.8,0,0.8-0.8l0,0l0,0V236H204l0,0l0,0 v0.8h-0.8V236H200.8L200.8,236L200.8,236L200.8,236L200.8,236L200.8,236L200.8,236z"
          ></path>{" "}
          <path
            style={{ fill: "#FFE600" }}
            d="M192.8,236H192l0,0l0,0v1.6l0,0l0,0h3.2c0,0,0.8,0,0.8-0.8v-1.6h-0.8v0.8h-0.8v-0.8L192.8,236 L192.8,236L192.8,236L192.8,236L192.8,236z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFE600" }}
            points="192,238.4 192,238.4 192,240.8 192,240.8 195.2,240.8 195.2,238.4 195.2,238.4 "
          ></polygon>{" "}
        </g>{" "}
        <g>
          {" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="138.4,275.2 136,272.8 136,272.8 136,272.8 134.4,275.2 134.4,275.2 136,277.6 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M144.8,279.2C144,279.2,144,279.2,144.8,279.2l-3.2,2.4l0,0L144.8,279.2L144.8,279.2L144.8,279.2 l-4-3.2l0,0v-0.8H140l0,0v-0.8h-0.8l-8.8,8.8v0.8l0,0l0,0l0,0v0.8l0,0l0,0l0,0l0,0l3.2,4l2.4-2.4l0,0l-2.4,2.4v0.8h0.8l9.6-9.6 C144.8,280,144.8,280,144.8,279.2L144.8,279.2z M135.2,283.2c0,0.8,0,1.6,0.8,2.4C136,284.8,135.2,284,135.2,283.2z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M128,275.2l0.8,0.8h0.8l2.4-2.4v-0.8l-1.6-0.8l0,0l0,0l0.8,0.8l-0.8,0.8l-0.8-0.8l0,0l-0.8,0.8l0,0 l0,0l0.8,0.8l-0.8,0.8L128,275.2L128,275.2L128,275.2L128,275.2L128,275.2z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M132,274.4L132,274.4L132,274.4z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="132,274.4 129.6,276.8 129.6,276.8 129.6,276.8 132.8,280 135.2,277.6 135.2,277.6 135.2,277.6 "
          ></polygon>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M133.6,272.8L133.6,272.8v0.8V272.8l-0.8,0.8l0,0l0,0l0,0l0,0l0.8,0.8h0.8l2.4-2.4v-0.8l0,0l0,0 l-0.8-0.8l-1.6,1.6l0,0l0,0l0.8,0.8l-0.8,0.8L133.6,272.8L133.6,272.8L133.6,272.8L133.6,272.8z"
          ></path>{" "}
          <path
            style={{ fill: "#FFFF00" }}
            d="M130.4,278.4l-0.8-0.8l-0.8,0.8l0.8,0.8l-0.8,0.8l-0.8-1.6v0.8l0.8,0.8H128l-0.8-0.8l-0.8,0.8l0,0 l0.8,0.8c0,0,0,0,0.8,0l0,0L130.4,278.4L130.4,278.4z"
          ></path>{" "}
          <polygon
            style={{ fill: "#FFFF00" }}
            points="132.8,280.8 130.4,279.2 130.4,279.2 130.4,279.2 128,280.8 130.4,283.2 "
          ></polygon>{" "}
        </g>{" "}
        <path
          style={{ fill: "#FFE600" }}
          d="M162.4,169.6c-43.2,0-78.4,35.2-78.4,78.4s35.2,78.4,78.4,78.4s78.4-35.2,78.4-78.4 C240.8,204.8,204.8,169.6,162.4,169.6z M209.6,194.4v62.4l0,0c0,12.8-5.6,24.8-13.6,32.8c-8.8,8.8-20,13.6-33.6,13.6 c-12.8,0-24.8-5.6-32.8-13.6c-8.8-8.8-13.6-20-13.6-32.8l0,0v-62.4H209.6z"
        ></path>{" "}
        <path
          style={{ fill: "#FFFF00" }}
          d="M84,248c0,43.2,35.2,78.4,78.4,78.4l0,0V304l0,0c-12.8,0-24.8-5.6-32.8-13.6 c-8.8-8.8-13.6-20-13.6-32.8l0,0v-62.4h47.2v-24l0,0C119.2,169.6,84,204.8,84,248z"
        ></path>{" "}
        <g>
          {" "}
          <path
            style={{ fill: "#005CB7" }}
            d="M162.4,256L162.4,256c1.6,0,3.2-0.8,4.8-1.6c0.8-0.8,1.6-2.4,1.6-4.8v-8.8h-6.4L162.4,256L162.4,256 z"
          ></path>{" "}
          <path
            style={{ fill: "#005CB7" }}
            d="M162.4,236L162.4,236c1.6,0,3.2-0.8,4.8-1.6s1.6-2.4,1.6-4.8v-8.8h-6.4L162.4,236L162.4,236z"
          ></path>{" "}
          <path
            style={{ fill: "#005CB7" }}
            d="M162.4,276.8L162.4,276.8c1.6,0,3.2-0.8,4.8-1.6s1.6-2.4,1.6-4.8v-8.8h-6.4L162.4,276.8L162.4,276.8 z"
          ></path>{" "}
        </g>{" "}
      </g>
    </svg>
  );
}

export { PortugueseIcon };
