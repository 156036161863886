import type { ForwardRefRenderFunction } from "react";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

import { forwardRef } from "react";
import { gql } from "@apollo/client";
import { useApolloQuery } from "../../hooks";

export type PositionsDropdownProps = SelectProps & {};

const positionsQueryDocument = gql`
  query Positions {
    positions {
      label: description
      value: id
    }
  }
`;

const PositionsDropdownComp: ForwardRefRenderFunction<
  HTMLElement,
  PositionsDropdownProps
> = (props, ref) => {
  const { children, ...selectProps } = props;

  const { data, loading } = useApolloQuery({
    queryDocument: positionsQueryDocument,
  });

  return (
    <Select
      ref={ref}
      displayEmpty={!loading}
      startAdornment={
        loading && (
          <InputAdornment position="start">
            <CircularProgress size={20} />
          </InputAdornment>
        )
      }
      {...selectProps}
    >
      <MenuItem value="" disabled>
        Select position
      </MenuItem>
      {data?.positions.map(
        (op: { value: string; label: string }, i: number) => (
          <MenuItem key={`${op.value}-${i}`} value={op.value}>
            {op.label}
          </MenuItem>
        )
      )}
    </Select>
  );
};

const PositionsDropdown = forwardRef(PositionsDropdownComp);

export { PositionsDropdown };
