import type { FC } from "react";

import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { Controller } from "react-hook-form";
import { MdAdd, MdOutlineRemove } from "react-icons/md";
import {
  ActionsDropdown,
  ThemesDropdown,
  CountriesDropdown,
} from "../../components";

import { useCallback } from "react";
import { useFieldArray } from "react-hook-form";

export type CareerActionsFormProps = {
  name: string;
  disabled?: boolean;
};

const CareerActionsForm: FC<CareerActionsFormProps> = (props) => {
  const { name, disabled } = props;

  const { fields, append, remove } = useFieldArray({
    name,
  });

  const handleAddAction = useCallback(() => {
    append({
      actionId: "",
      themeId: "",
      countryId: "",
      actionDate: "",
    });
  }, [append]);

  return (
    <Stack gap={2}>
      {fields.map((data, i) => (
        <Stack key={data.id} direction={{ md: "row" }} gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                name={`${name}.${i}.actionId`}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    disabled={disabled}
                    error={Boolean(error)}
                    fullWidth
                  >
                    <FormLabel>Action</FormLabel>
                    <ActionsDropdown {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                name={`${name}.${i}.themeId`}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    disabled={disabled}
                    error={Boolean(error)}
                    fullWidth
                  >
                    <FormLabel>Sector</FormLabel>
                    <ThemesDropdown {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                name={`${name}.${i}.countryId`}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    disabled={disabled}
                    error={Boolean(error)}
                    fullWidth
                  >
                    <FormLabel>Country</FormLabel>
                    <CountriesDropdown {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                name={`${name}.${i}.actionDate`}
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    disabled={disabled}
                    error={Boolean(error)}
                    fullWidth
                  >
                    <FormLabel>Date</FormLabel>
                    <OutlinedInput placeholder="Select date" {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>

          <div style={{ flexShrink: 0 }}>
            <FormLabel>&nbsp;</FormLabel>
            <Button
              fullWidth
              variant="outlined"
              color="error"
              startIcon={<MdOutlineRemove size={24} />}
              disabled={disabled}
              onClick={() => remove(i)}
              size="large"
            >
              Remove
            </Button>
          </div>
        </Stack>
      ))}
      <div>
        <Button
          color="success"
          variant="outlined"
          startIcon={<MdAdd size={24} />}
          disabled={disabled}
          onClick={handleAddAction}
          size="large"
        >
          Add action
        </Button>
      </div>
    </Stack>
  );
};

export default CareerActionsForm;
