import React, { FormEventHandler } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import { useState, useCallback } from "react";
import { useAuthSession } from "../../components/AuthSessionProvider";
import { useNotify } from "../../components/@common/NotificationsProvider";
import { useLanguageTranslator } from "../../components";

const Login: React.FC = () => {
  const notify = useNotify();
  const { loginUser } = useAuthSession();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signingIn, setSigningIn] = useState(false);

  const { translator } = useLanguageTranslator();

  /**
   * handleSignin()
   */
  const handleSignin: FormEventHandler<HTMLFormElement> = useCallback(
    async (ev) => {
      try {
        ev.preventDefault();

        setSigningIn(true);
        await loginUser(email, password);
      } catch (err) {
        notify.error(err as Error);
        setSigningIn(false);
      }
    },
    [email, password, loginUser, notify]
  );

  const isFormEmpty = !email.trim() || !password.trim();

  return (
    <Stack sx={{ height: "100%" }} justifyContent="center">
      <Container maxWidth="xs">
        <Box mb={4}>
          <Typography variant="h3" align="center" gutterBottom>
            {translator("Welcome back")}
          </Typography>
          <Typography align="center">
            {translator("Welcome back")}!{" "}
            {translator("Please enter your details")}.
          </Typography>
        </Box>

        <Stack component="form" spacing={1.5} onSubmit={handleSignin}>
          <FormControl disabled={signingIn}>
            <FormLabel htmlFor="email">{translator("Email")}</FormLabel>
            <OutlinedInput
              id="email"
              name="email"
              type="email"
              autoFocus
              required
              value={email}
              onChange={(ev) => setEmail(ev.target.value)}
            />
          </FormControl>

          <FormControl disabled={signingIn}>
            <FormLabel htmlFor="password">{translator("Password")}</FormLabel>
            <OutlinedInput
              id="password"
              type="password"
              required
              value={password}
              onChange={(ev) => setPassword(ev.target.value)}
            />
          </FormControl>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormControlLabel
              label={translator("Remember for 30 days")}
              control={<Checkbox size="small" />}
            />

            <Link
              component={RouterLink}
              to="#"
              tabIndex={-1}
              color="inherit"
              underline="hover"
              variant="body2"
              fontWeight={500}
            >
              {translator("Forgot")} {translator("Password")}
            </Link>
          </Stack>

          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={isFormEmpty || signingIn}
          >
            {translator("Sign in")}
          </Button>
        </Stack>

        <center style={{ marginTop: 32 }}>
          <Link
            href="/"
            tabIndex={-1}
            color="textSecondary"
            variant="caption"
            fontWeight={500}
          >
            {translator("Back to Home")}
          </Link>
        </center>
      </Container>
    </Stack>
  );
};

export default Login;
