import { useMemo } from "react";
import { gql } from "@apollo/client";
import { useApolloQuery } from "../useApolloQuery";

export type PolicyStatus = "lettresSectorielles" | "other" | "décrets" | "lois";

export type PolicyData = {
  id: string | number;
  title: string;
  description: string;
  picture?: string;
  status: PolicyStatus;
  policyCategory: { name: string };
};

export type PoliciesCollectionData = {
  policies: { collection: PolicyData[]; metadata: {} };
};

const policiesQueryDocument = gql`
  query policiesQuery(
    $limit: Int
    $page: Int
    $countryId: Int
    $themeId: Int
    $startYear: Int
    $endYear: Int
    $geographicalAreaId: Int
  ) {
    policies(
      limit: $limit
      page: $page
      countryId: $countryId
      themeId: $themeId
      startYear: $startYear
      endYear: $endYear
      geographicalAreaId: $geographicalAreaId
    ) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        geographicalArea {
          name
        }
        id
        link
        picture
        policyCategory {
          name
        }
        status
        theme {
          id
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const policiesCategoryQueryDocument = gql`
  query GetPolicyCategories {
    policyCategories {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

function usePublicPoliciesCall({
  variables,
  currTab = "",
}: {
  currTab?: PolicyStatus | "" | "all";
  variables: any;
}) {
  const { data, loading, refetch } = useApolloQuery({
    queryDocument: policiesQueryDocument,
    variables: variables,
  });

  const { data: category } = useApolloQuery({
    queryDocument: policiesCategoryQueryDocument,
  });

  const dist = useMemo(() => {
    if (!category?.policyCategories || !data?.policies?.collection) return {};

    const dynamicDist: Record<string, PolicyData[]> = {};

    category.policyCategories.forEach((cat: any) => {
      const categoryName = cat.name.toLowerCase();
      dynamicDist[categoryName] = [];
    });

    dynamicDist.other = [];

    data.policies.collection.forEach((p: any) => {
      const policyCategoryName = p?.policyCategory?.name?.toLowerCase();
      if (policyCategoryName && dynamicDist[policyCategoryName]) {
        dynamicDist[policyCategoryName].push(p);
      } else {
        dynamicDist.other.push(p);
      }
    });

    return dynamicDist;
  }, [category?.policyCategories, data?.policies?.collection]);

  const publicTabs = useMemo(() => {
    if (!dist) return [];

    return Object.entries(dist).map(([key, value]) => ({
      value: key,
      count: value.length,
      title:
        key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1"),
    }));
  }, [dist]);

  const modifiedPublicTabs = [
    {
      count: data?.policies?.collection?.length,
      title: "All",
      value: "all",
    },
    ...(publicTabs || []),
  ];

  const filteredDataResults = useMemo(() => {
    if (currTab === "all" || !currTab) return data?.policies?.collection;
    return data?.policies?.collection?.filter(
      (item: any) => item?.policyCategory?.name?.toLowerCase() === currTab
    );
  }, [data?.policies?.collection, currTab]);

  return {
    policies: filteredDataResults || [],
    tabs: modifiedPublicTabs || [],
    loading,
    refetch,
    metadata: data?.policies?.metadata,
  };
}

export { usePublicPoliciesCall };
