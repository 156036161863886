import React, { createContext, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuthSession } from "../AuthSessionProvider";
import { t } from "i18next";

const LanguageContext = createContext<any>(null);

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuthSession() as any;

  const [currentLanguage, setCurrentLanguage] = useState(
    user?.lang ? user?.lang : localStorage?.getItem("lang") || "en"
  );
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage, i18n]);

  useEffect(() => {
    if (user?.lang) {
      setCurrentLanguage(user.lang);
    } else {
      const storedLang = localStorage.getItem("lang") || "en";
      setCurrentLanguage(storedLang);
    }
  }, [user?.lang]);

  const changeLanguage = (language: string) => {
    setCurrentLanguage(language);
    localStorage.setItem("lang", language);
  };

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        translator: t,
        setCurrentLanguage: changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguageTranslator() {
  return useContext(LanguageContext);
}
