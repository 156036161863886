import {
  Container,
  Link,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import TabLabel from "../@common/TabLabel";
import { Link as RouterLink } from "react-router-dom";
import { ConditionalRenderer } from "../conditionalRenderer";

function TabsFilters({
  currTab,
  setCurrTab,
  listItems,
  heading,
  isAllShow,
}: any) {
  return (
    <Paper component={Container} sx={{ py: 1, mb: 2 }}>
      <Stack
        direction={{ lg: "row" }}
        justifyContent={{ lg: "space-between" }}
        alignItems={{ lg: "center" }}
      >
        <Typography variant="h4">{heading}</Typography>

        <Tabs
          value={currTab}
          onChange={(_, val) => setCurrTab(val)}
          variant="scrollable"
          sx={{ my: { xs: 1, lg: 0 } }}
        >
          {listItems?.map(({ value, count, title }: any) => (
            <Tab
              value={value}
              label={<TabLabel count={count}>{title}</TabLabel>}
            />
          ))}
        </Tabs>
        <ConditionalRenderer condition={!isAllShow}>
          <Link
            component={RouterLink}
            to="all"
            underline="none"
            color="textSecondary"
          >
            View all
          </Link>
        </ConditionalRenderer>
      </Stack>
    </Paper>
  );
}

export { TabsFilters };
