import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import {
  Card,
  ConditionalRenderer,
  useLanguageTranslator,
} from "../../components";
import { useApolloQuery } from "../../hooks";
import { gql } from "@apollo/client";
import { DownArrowIcon } from "../../@lib/icons";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const noPendingPolicyDocument = gql`
  query ($limit: Int, $page: Int) {
    noPendingPolicies(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        geographicalArea {
          name
        }
        id
        link
        picture
        policyCategory {
          name
        }
        status
        theme {
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const noPendingPracticesDocument = gql`
  query ($limit: Int, $page: Int) {
    noPendingPractices(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        id
        link
        picture
        policy {
          title
        }
        practiceCategory {
          name
        }
        practiceType {
          name
        }
        status
        theme {
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const noPendingPeopleDocument = gql`
  query ($limit: Int, $page: Int) {
    noPendingPeople(limit: $limit, page: $page) {
      collection {
        country
        createdAt
        firstname
        id
        lastname
        picture
        status
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

function isModule(location: any) {
  const isPractices = location?.pathname?.includes("practices") || false;
  const isPolicies = location?.pathname?.includes("policies") || false;

  if (isPolicies) {
    return {
      queryDocument: noPendingPolicyDocument,
      createPath: "/admin/policies/new",
      heading: "Policies",
    };
  } else if (isPractices) {
    return {
      queryDocument: noPendingPracticesDocument,
      createPath: "/admin/practices/new",
      heading: "Practices",
    };
  } else {
    return {
      queryDocument: noPendingPeopleDocument,
      createPath: "/admin/people/new",
      heading: "People",
    };
  }
}

function AdminViewAll() {
  const [limit, setLimit] = useState(8);

  const location = useLocation();
  const { translator } = useLanguageTranslator();

  const { queryDocument, createPath, heading }: any = isModule(location);

  const {
    data: noPending,
    loading,
    refetch,
  } = useApolloQuery({
    queryDocument: queryDocument,
    isPrivateCall: true,
    variables: {
      limit: limit,
    },
  });

  function onClickHandler() {
    setLimit(limit + 8);
  }

  const data =
    noPending?.noPendingPolicies?.collection ||
    noPending?.noPendingPractices?.collection ||
    noPending?.noPendingPeople?.collection ||
    [];

  const metadata =
    noPending?.noPendingPolicies?.metadata ||
    noPending?.noPendingPractices?.metadata ||
    noPending?.noPendingPeople?.metadata;

  const hasMore =
    limit === metadata?.totalCount ||
    limit > metadata?.totalCount ||
    !metadata?.totalCount;

  return (
    <Container sx={{ my: 3 }}>
      <Paper component={Container} sx={{ py: 2, mb: 2 }}>
        <Stack
          direction={{ lg: "row" }}
          justifyContent={{ lg: "space-between" }}
          alignItems={{ lg: "center" }}
        >
          <Typography variant="h4">Approved & Rejected {heading}</Typography>
          <Box>
            <Button
              href={createPath}
              sx={{
                px: 6,
                backgroundColor: "#F6A000",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              {translator("generic.CREATE")}
            </Button>
          </Box>
        </Stack>
      </Paper>
      <Grid container spacing={2}>
        {data?.map((p: any, i: any) => (
          <Grid key={`${i}`} item xs={12} md={6} lg={3}>
            <Slide in timeout={180 * (i + 1)} direction="right">
              <Card
                {...p}
                //   path={`${viewPath}${p?.id}`}
                sx={{ height: "100%" }}
                isAdmin={true}
                //   onAdminActions={onAdminActions}
              />
            </Slide>
          </Grid>
        ))}

        {/* {loading &&
            policies.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {CardSkeleton}
                  </Grid>
                </Fade>
              ))} */}
      </Grid>
      <Paper>
        <ConditionalRenderer condition={!hasMore}>
          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button style={{ color: "#979da7" }} onClick={onClickHandler}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                Show more <DownArrowIcon />
              </div>
            </Button>
          </div>
        </ConditionalRenderer>
      </Paper>
    </Container>
  );
}

export { AdminViewAll };
