import { useQuery, gql } from "@apollo/client";
import { useLanguageTranslator } from "../../components";

const peopleQueryDocument = gql`
  query People(
    $limit: Int
    $page: Int
    $countryId: Int
    $themeId: Int
    $startYear: Int
    $endYear: Int
  ) {
    people(
      limit: $limit
      page: $page
      countryId: $countryId
      themeId: $themeId
      startYear: $startYear
      endYear: $endYear
    ) {
      collection {
        createdAt
        firstname
        id
        lastname
        picture
        status
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const privateQueryDocument = gql`
  query People(
    $limit: Int
    $page: Int
    $countryId: Int
    $themeId: Int
    $startYear: Int
    $endYear: Int
  ) {
    people(
      limit: $limit
      page: $page
      countryId: $countryId
      themeId: $themeId
      startYear: $startYear
      endYear: $endYear
    ) {
      collection {
        createdAt
        firstname
        id
        lastname
        picture
        status
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const usePeoplesCall = ({
  isPrivate = false,
  variables = {},
  user = { accessToken: "", client: "", id: "" },
}: any) => {
  const { currentLanguage } = useLanguageTranslator();

  const queryDocument = isPrivate ? privateQueryDocument : peopleQueryDocument;

  const paramsObject: any = isPrivate
    ? {
        fetchPolicy: "network-only",
        variables: variables,
        skip: !user?.accessToken || !user?.client || !user?.id,
        context: {
          headers: {
            "access-token": user?.accessToken,
            client: user?.client,
            uid: user?.id,
            "accept-language": currentLanguage || "en",
          },
        },
      }
    : {
        fetchPolicy: "network-only",
        variables: variables,
        context: {
          headers: {
            "accept-language": currentLanguage || "en",
          },
        },
      };

  const { data, loading, refetch } = useQuery<any>(queryDocument, paramsObject);

  return {
    data,
    loading,
    refetch,
  };
};

export { usePeoplesCall };
