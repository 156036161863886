import type { ForwardRefRenderFunction } from "react";
import { forwardRef } from "react";
import { gql } from "@apollo/client";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

import { useApolloQuery } from "../../hooks";

export type RolesDropdownProps = SelectProps & {};

const rolesQueryDocument = gql`
  query Roles {
    roles {
      label: name
      value: id
    }
  }
`;

const RolesDropdownComp: ForwardRefRenderFunction<
  HTMLElement,
  RolesDropdownProps
> = (props, ref) => {
  const { children, ...selectProps } = props;

  const { data, loading } = useApolloQuery({
    queryDocument: rolesQueryDocument,
  });

  return (
    <Select
      ref={ref}
      displayEmpty={!loading}
      startAdornment={
        loading && (
          <InputAdornment position="start">
            <CircularProgress size={20} />
          </InputAdornment>
        )
      }
      {...selectProps}
    >
      <MenuItem value="" disabled>
        Select role
      </MenuItem>
      {data?.roles.map((op: { value: string; label: string }, i: number) => (
        <MenuItem key={`${op.value}-${i}`} value={op.value}>
          {op.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const RolesDropdown = forwardRef(RolesDropdownComp);

export { RolesDropdown };
