import React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Stepper, { Step } from "../../components/@common/Stepper";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import PolicyInfoForm, { PolicyInfoFormProps } from "./PolicyInfoForm";
import PracticesInfoForm from "./PracticesInfoForm";
import PeopleInfoForm from "./PeopleInfoForm";
import { FormProvider } from "react-hook-form";

import { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { gql } from "@apollo/client";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as PolicyIcon } from "../../@lib/icons/policy-icon.svg";
import { ReactComponent as PracticesIcon } from "../../@lib/icons/practices-icon.svg";
import { ReactComponent as PersonIcon } from "../../@lib/icons/person-icon.svg";
import { useApolloQuery } from "../../hooks";

export type PolicyInputData = {
  title: string;
  description: string;
  date?: string;
  link?: string;
  policyCategoryId: string | number;
  themeId: string | number;
  countryId: string | number;
  geographicalAreaId: string | number;
};

export type PolicyEditorProps = {
  initialValues?: PolicyInputData;
  disabled?: boolean;
  onFinish: (policyData: PolicyInputData) => void;
  onCancel: () => void;
};

export type PolicyFormOptionsData = PolicyInfoFormProps;

const policyFormOptionsQueryDocument = gql`
  query policyFormOptionsQuery {
    countryOptions: countries {
      value: id
      label: name
    }
    themeOptions: themes {
      value: id
      label: title
    }
    geographicalAreaOptions: geographicalAreas {
      value: id
      label: name
      policyCategoryOptions: policyCategories {
        value: id
        label: name
      }
    }
  }
`;

const STEPS: Step[] = [
  {
    label: "Policy Information",
    icon: <SvgIcon component={PolicyIcon} inheritViewBox />,
  },
  {
    label: "Practices",
    icon: <SvgIcon component={PracticesIcon} inheritViewBox />,
  },
  { label: "People", icon: <SvgIcon component={PersonIcon} inheritViewBox /> },
];

const PolicyEditor: React.FC<PolicyEditorProps> = (props) => {
  const { initialValues, disabled, onFinish, onCancel } = props;

  const { data, loading } = useApolloQuery({
    queryDocument: policyFormOptionsQueryDocument,
  });

  const totalSteps = STEPS.length;
  const [currStepIndex, setCurrStepIndex] = useState(0);
  const form = useForm<PolicyInputData>({
    defaultValues: {
      countryId: "",
      themeId: "",
      policyCategoryId: "",
      geographicalAreaId: "",
    },
    values: initialValues,
  });

  /**
   * handleContinue()
   */
  const handleContinue = useCallback(async () => {
    // TODO: Implement step level validation by validating fields only related to current step.
    const isFormValid = await (() => {
      switch (currStepIndex) {
        case 0:
        case 1:
        case 2:
        default: {
          return form.trigger();
        }
      }
    })();

    if (isFormValid) setCurrStepIndex((currVal) => ++currVal);
    else void 0; // TODO: Implement Notification snackbar.
  }, [currStepIndex, form]);

  /**
   * handleBack()
   */
  const handleBack = useCallback(
    () => setCurrStepIndex((currVal) => --currVal),
    []
  );

  return (
    <div>
      <Container maxWidth="sm">
        <Stepper steps={STEPS} activeStep={currStepIndex} />
      </Container>

      <Divider sx={{ m: 4, opacity: 0.5 }} />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onFinish)}>
          {currStepIndex === 0 && (
            <PolicyInfoForm
              themeOptions={data?.themeOptions || []}
              countryOptions={data?.countryOptions || []}
              geographicalAreaOptions={data?.geographicalAreaOptions || []}
              disabled={disabled || loading}
            />
          )}
          {currStepIndex === 1 && (
            <PracticesInfoForm disabled={disabled || loading} />
          )}
          {currStepIndex === 2 && (
            <PeopleInfoForm disabled={disabled || loading} />
          )}

          <Stack direction="row" justifyContent="flex-end" gap={2} mt={3}>
            {currStepIndex === 0 && (
              <Button
                variant="contained"
                color="inherit"
                disabled={disabled}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
            {currStepIndex > 0 && currStepIndex < totalSteps && (
              <Button
                variant="contained"
                color="inherit"
                disabled={disabled}
                onClick={handleBack}
              >
                Back
              </Button>
            )}
            {currStepIndex < totalSteps - 1 && (
              <Button
                variant="contained"
                disabled={disabled}
                onClick={handleContinue}
              >
                Continue
              </Button>
            )}
            {currStepIndex === totalSteps - 1 && (
              <Button type="submit" variant="contained" disabled={disabled}>
                Finish
              </Button>
            )}
          </Stack>
        </form>
      </FormProvider>
    </div>
  );
};

export { PolicyEditor };
