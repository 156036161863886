import { useMemo, useState } from "react";
import {
  Box,
  Container,
  Stack,
  Paper,
  Typography,
  Link,
  Grid,
  Button,
  styled,
} from "@mui/material";

import { Link as RouterLink, useLocation } from "react-router-dom";
import PersonCard from "../../../components/PersonCard";
import StatusTag from "../../../components/@common/StatusTag";
import { useAuthSession } from "../../../components/AuthSessionProvider";
import { Listings } from "../components";
import {
  Filters,
  Header,
  useLanguageTranslator,
  CTAContainer,
  ConditionalRenderer,
  TabsFilters,
} from "../../../components";
import { useLatestActions, useLatestPositions, usePeoplesCall } from "../../../hooks";
import { useFilters } from "../helpers";

const StatusWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  backgroundColor: "#ffffff",
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  boxShadow: theme.shadows[3],
}));

function PeopleHome() {
  const [filters, setFilters] = useState({
    country: "",
    startYear: "",
    endYear: "",
    theme: "",
  });
  const [currTab, setCurrTab] = useState("all");

  const { user } = useAuthSession();
  const isLoggedIn = !!user;

  const location = useLocation();
  const isPrivate = location?.pathname?.includes("/submission/people");
  const { translator } = useLanguageTranslator();

  const { data } = usePeoplesCall({
    isPrivate,
    variables: {
      limit: 8,
      ...(filters?.country ? { countryId: Number(filters?.country) } : {}),
      ...(filters?.theme ? { themeId: Number(filters?.theme) } : {}),
      ...(filters?.startYear ? { startYear: Number(filters?.startYear) } : {}),
      ...(filters?.endYear ? { endYear: Number(filters?.endYear) } : {}),
    },
    user,
  });

  const { tabsData, people = [] }: any = useFilters({ isPrivate, data });

  const { data: latestActions } = useLatestActions({ variables: { limit: 8 } });
  const { data: latestPositions } = useLatestPositions({
    variables: { limit: 8 },
  });

  const filteredDataResults = useMemo(() => {
    if (currTab === "all") return people;
    return people?.filter(
      (item: any) => item.status?.toLowerCase() === currTab
    );
  }, [people, currTab]);

  const modifiedData = isPrivate
    ? filteredDataResults
    : data?.people?.collection;

  return (
    <Box pb={4}>
      <Header
        title={translator("People")}
        subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX"
      />

      <Container sx={{ my: 3 }}>
        <ConditionalRenderer condition={isPrivate}>
          <TabsFilters
            currTab={currTab}
            setCurrTab={setCurrTab}
            listItems={tabsData}
            heading={`${translator("List of")} ${translator("People")}`}
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={!isPrivate}>
          <Paper
            component={Container}
            sx={{
              py: 1,
              mb: 2,
              backgroundColor: "primary.main",
              color: "#ffffff",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">
                {translator("People")}
              </Typography>
              <Link
                component={RouterLink}
                to="all"
                underline="none"
                color="inherit"
              >
                {translator("View all")}
              </Link>
            </Stack>
          </Paper>

          <Filters setFilters={setFilters} filters={filters} />
        </ConditionalRenderer>

        <Grid container spacing={3}>
          {modifiedData?.map((person: any, i: any) => (
            <Grid
              key={`people-card-${i}-${person.id}`}
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <PersonCard
                person={person}
                isPrivate
                editPath={`/submission/people/${person?.id}`}
              >
                <ConditionalRenderer condition={isPrivate}>
                  <StatusWrapper>
                    <StatusTag label={person?.status} status={person?.status} />
                  </StatusWrapper>
                </ConditionalRenderer>
              </PersonCard>
            </Grid>
          ))}
        </Grid>
      </Container>

      <ConditionalRenderer condition={isLoggedIn && isPrivate}>
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" mb={2}>
              {translator("Add new people to the platform")}
            </Typography>
            <Button
              component={RouterLink}
              to="new"
              sx={{
                px: 6,
                backgroundColor: "#ffffff",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              {`${translator("Add")} ${translator("People")}`}
            </Button>
          </CTAContainer>
        </Container>
      </ConditionalRenderer>

      <Listings
        title={`${translator("Latest")} ${translator("Actions")}`}
        data={latestActions?.collection}
      />
      <Listings
        title={`${translator("Latest")} ${translator("Positions")}`}
        data={latestPositions?.collection}
      />
    </Box>
  );
}

export { PeopleHome };
