import React, { useState } from "react";
import type { PersonInputData, IDType } from "../../@lib/types";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { FormProvider, Controller } from "react-hook-form";
import CareersForm from "./CareersForm";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Attachment } from "../../features";

export type PersonEditorProps = {
  initialValues?: PersonInputData;
  disabled?: boolean;
  onSave: (data: PersonInputData) => void;
  onCancel: () => void;
};

const yupIdType = yup
  .mixed(
    (input): input is IDType =>
      typeof input === "string" || typeof input === "number"
  )
  .transform((v) => (!v ? undefined : v));

const schema = yup.object({
  firstname: yup.string().required("Required!"),
  lastname: yup.string().required("Required!"),
  title: yup.string().required("Required!"),
  careers: yup
    .array(
      yup.object({
        institutionId: yupIdType.required("Required!"),
        roleId: yupIdType.required("Required!"),
        startDate: yup.string().required("Required!"),
        endDate: yup.string().required("Required!"),
        careerActions: yup
          .array(
            yup.object({
              actionId: yupIdType,
              themeId: yupIdType,
              countryId: yupIdType,
              actionDate: yup.string(),
            })
          )
          .min(1),
        careerPositions: yup
          .array(
            yup.object({
              positionId: yupIdType,
              themeId: yupIdType,
              countryId: yupIdType,
              positionDate: yup.string(),
            })
          )
          .min(1),
      })
    )
    .required(),
});

const PersonEditor: React.FC<PersonEditorProps> = (props) => {
  const [imageAttachmentDetails, setImageAttachmentDetails] = useState({
    name: "",
  });
  const { initialValues, onSave, onCancel, disabled } = props;

  const form = useForm<PersonInputData>({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: "",
      lastname: "",
      title: "",
      careers: [
        {
          startDate: "",
          endDate: "",
          institutionId: "",
          roleId: "",
          careerActions: [
            {
              actionId: "",
              themeId: "",
              countryId: "",
              actionDate: "",
            },
          ],
          careerPositions: [
            {
              positionId: "",
              themeId: "",
              countryId: "",
              positionDate: "",
            },
          ],
        },
      ],
    },
    values: initialValues,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSave)}>
        <Paper component={Container} sx={{ py: 3, mb: 3 }}>
          <Stack direction={{ md: "row" }} spacing={3}>
            <div style={{ flex: 1 }}>
              <Attachment
                allowedFormats={["svg", "png", "jpg", "gif", "jpeg"]}
                text="SVG, PNG, JPG or GIF (max. 800x400px)"
                setAttachmentDetails={setImageAttachmentDetails}
                attachmentDetails={imageAttachmentDetails}
                variant={{ variant: "outlined", py: 10 }}
              />
            </div>
            <Stack spacing={2} flex={1}>
              <Controller
                name="title"
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    required
                    disabled={disabled}
                    error={Boolean(error)}
                  >
                    <FormLabel>Title</FormLabel>
                    <OutlinedInput {...field} placeholder="Enter title" />
                    <FormHelperText>{error && error.message}</FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="firstname"
                // rules={{ required: 'Required!' }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl required disabled={disabled} error={!!error}>
                    <FormLabel>First name</FormLabel>
                    <OutlinedInput {...field} placeholder="Enter first name" />
                    <FormHelperText>{error && error.message}</FormHelperText>
                  </FormControl>
                )}
              />

              <Controller
                name="lastname"
                render={({ field, fieldState: { error } }) => (
                  <FormControl
                    required
                    disabled={disabled}
                    error={Boolean(error)}
                  >
                    <FormLabel>Last name</FormLabel>
                    <OutlinedInput {...field} placeholder="Enter last name" />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>
        </Paper>

        <CareersForm name="careers" disabled={disabled} />

        <Paper component={Container} sx={{ py: 2, mt: 2 }}>
          <Stack direction="row" justifyContent="flex-end" gap={1}>
            <Button
              variant="contained"
              color="inherit"
              sx={{ px: 6 }}
              disabled={disabled}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{ px: 6 }}
              disabled={disabled}
            >
              Save
            </Button>
          </Stack>
        </Paper>
      </form>
    </FormProvider>
  );
};

export { PersonEditor };
