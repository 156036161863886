import Container, { ContainerProps } from "@mui/material/Container";
import ctaBanner from "../../@lib/images/cta-banner.png";
import { styled } from "@mui/material/styles";

export type CTAContainerProps = ContainerProps;

const CTAContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  backgroundImage: `url(${ctaBanner})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  color: "#ffffff",
})) as typeof Container;

export { CTAContainer };
