import { useState, useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import { Link as RouterLink } from "react-router-dom";
import { Container, Stack, Paper, Typography, Button } from "@mui/material";


import type { Policy } from "../../../@lib/types";

import { useAuthSession } from "../../../components/AuthSessionProvider";
import { useNotify } from "../../../components/@common/NotificationsProvider";
import {
  CardAll,
  ConditionalRenderer,
  Header,
  TabsFilters,
  CTAContainer
} from "../../../components";
import { usePrivatePoliciesCall } from "../../../hooks";

const policyDeleteMutationDocument = gql`
  mutation policyDeleteMutation($policy: PolicyDeleteInput!) {
    policyDelete(input: $policy) {
      policy {
        id
      }
    }
  }
`;

function PrivatePoliciesList() {
  const notify = useNotify();
  const { user } = useAuthSession();
  const isLoggedIn = !!user;
  const [deletePolicy, { loading: deletingPolicy }] = useMutation(
    policyDeleteMutationDocument
  );
  const [currTab, setCurrTab] = useState< "all" | any>("all");
  const [pageNumber, setPageNumber] = useState(1);

  const { policies, dist, loading, refetch, metadata } = usePrivatePoliciesCall(
    {
      user,
      policyStatus: currTab,
      variables: { limit: 8, page: pageNumber },
    }
  );

  function onClickHandler() {
    if (pageNumber === metadata?.totalPages) return;
    setPageNumber(pageNumber + 1);
  }

  /**
   * handleDeletePolicy()
   */
  const handleDeletePolicy = useCallback(
    async (policy: Pick<Policy, "id" | "title">) => {
      if (
        await notify.confirm(
          `Deleting policy "${policy.title}". This cannot be undone.`
        )
      ) {
        try {
          await deletePolicy({ variables: { policy: { id: policy.id } } });
          refetch();
        } catch (err) {
          notify.error(err as Error);
        }
      }
    },
    [deletePolicy, refetch, notify]
  );


  const privateTab = [
    {
      value: "all",
      count: dist.all.length,
      title: "All",
    },
    {
      value: "approved",
      count: dist.approved.length,
      title: "Approved",
    },
    {
      value: "pending",
      count: dist.pending.length,
      title: "Pending",
    },
    {
      value: "rejected",
      count: dist.rejected.length,
      title: "Rejected",
    },
  ];

  const hasMore = pageNumber === metadata?.totalPages;

  return (
    <div>
      <Header
        title="Policies"
        subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
      />

      <Container sx={{ my: 3 }}>
        <TabsFilters
          dist={dist}
          currTab={currTab}
          setCurrTab={setCurrTab}
          listItems={privateTab}
          heading="List of policies data"
          isAllShow={true}
        />

        <Stack gap={2}>
          {policies.map((p, i) => (
            <CardAll
              p={p}
              loading={loading || deletingPolicy}
              path={`/policies/${p.id}`}
              isLoggedIn={isLoggedIn}
              editPath={`/policies/${p?.id}/edit`}
              handleDeletePolicy={handleDeletePolicy}
            />
          ))}
        </Stack>
      </Container>

      <ConditionalRenderer condition={!hasMore}>
        <Container sx={{ my: 3 }}>
          <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button onClick={onClickHandler}>Show more</Button>
          </Paper>
        </Container>
      </ConditionalRenderer>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" mb={2}>
              Add new policies to the platform
            </Typography>
            <Button
              component={RouterLink}
              to="/policies/new"
              sx={{
                px: 6,
                backgroundColor: "#ffffff",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              CREATE
            </Button>
          </CTAContainer>
        </Container>
      )}
    </div>
  );
}

export { PrivatePoliciesList };
