import { useQuery } from "@apollo/client";
import { useLanguageTranslator } from "../../components";
import { useAuthSession } from "../../components/AuthSessionProvider";

function useApolloQuery({
  queryDocument,
  variables = {},
  isPrivateCall = false,
}: any) {
  const { currentLanguage } = useLanguageTranslator();
  const { user } = useAuthSession() as any;

  const privateCallParams = {
    "access-token": user?.accessToken,
    client: user?.client,
    uid: user?.id,
  };

  const {
    data,
    loading,
    refetch = () => {},
  } = useQuery(queryDocument, {
    fetchPolicy: "network-only",
    variables: variables,
    context: {
      headers: {
        "accept-language": currentLanguage || "en",
        ...(isPrivateCall ? privateCallParams : {}),
      },
    },
  });

  return { data, loading, refetch };
}

export { useApolloQuery };
