import type { FC } from "react";
import type { OptionType, IDType } from "../../@lib/types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import OptionsAccordion from "./OptionsAccordion";
import TimeInputAccordion from "./TimeInputAccordion";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as ThemeIcon } from "../../@lib/icons/theme-icon.svg";
import { ReactComponent as CategoryIcon } from "../../@lib/icons/category-icon.svg";
import { ReactComponent as IndicatorIcon } from "../../@lib/icons/indicator-icon.svg";
import { ReactComponent as CountryIcon } from "../../@lib/icons/country-icon.svg";
import { ReactComponent as TimeIcon } from "../../@lib/icons/time-icon.svg";

import { useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useLazyQuery, gql } from "@apollo/client";
import { useApolloQuery } from "../../hooks";
import { ConditionalRenderer, useLanguageTranslator } from "../../components";

export type IndicatorOptions = {
  themeOptions: OptionType<IDType>[];
  categoryOptions: OptionType<IDType>[];
  countryOptions: OptionType<IDType>[];
};

export type SelectedOptions = {
  theme?: OptionType<IDType>;
  category?: OptionType<IDType>;
  indicatorIds?: IDType[];
  countries?: OptionType<IDType>[] | any;
  selectedCountryList?: any;
  selectedThemeList?: any;
  yearsRange?: {
    startYear: number;
    endYear: number;
  };
};

export type DataFiltersProps = {
  onUpdate: (opts: SelectedOptions) => void;
  selectedCountryID?: any;
  setSelectedCountryID?: any;
  selectedTheme?: any;
  setSelectedTheme?: any;
  setSelectedIndicators?: any;
  selectedIndicators?: any;
  isModule: Number;
};

const today = new Date();

const AlignedRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
}));

const optionsQueryDocument = gql`
  query Options {
    themeOptions: themes {
      label: title
      value: id
    }
    categoryOptions: indicatorCategories {
      label: name
      value: id
    }
    countryOptions: countries {
      label: name
      value: id
    }
  }
`;

const indicatorOptionsQueryDocument = gql`
  query IndicatorOptions($themeId: Int!, $indicatorCategoryId: Int!) {
    indicatorOptions: indicators(
      themeId: $themeId
      indicatorCategoryId: $indicatorCategoryId
    ) {
      label
      value: id
    }
  }
`;

const DataFilters: FC<DataFiltersProps> = (props) => {
  const {
    onUpdate,
    selectedCountryID,
    setSelectedCountryID,
    selectedTheme,
    setSelectedTheme,
    isModule,
    setSelectedIndicators,
    selectedIndicators,
  } = props;

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCountryList, setSelectedCountryList] = useState<any>([]);
  const [selectedThemeList, setSelectedThemeList] = useState<
    Set<IDType> | null | any
  >(new Set<IDType>());
  const [yearsRange, setYearRange] = useState({
    startYear: today.getFullYear() - 5,
    endYear: today.getFullYear(),
  });

  const { translator } = useLanguageTranslator();

  const { data, loading } = useApolloQuery({
    queryDocument: optionsQueryDocument,
  });

  const [
    getIndicators,
    { data: indicators, loading: loadingIndicatorOptions },
  ] = useLazyQuery<{ indicatorOptions: OptionType<IDType>[] }>(
    indicatorOptionsQueryDocument
  );

  const themeOption = useMemo(() => {
    return data?.themeOptions.find(
      (op: { value: string }) => op.value === selectedTheme
    );
  }, [data, selectedTheme]);

  const categoryOption = useMemo(() => {
    return data?.categoryOptions.find(
      (op: { value: string }) => op.value === selectedCategory
    );
  }, [data, selectedCategory]);

  const countryOption = useMemo(() => {
    return data?.countryOptions.find(
      (op: { value: string }) =>
        op.value === Array.from(selectedCountryID).join(",")
    );
  }, [data, selectedCountryID]);

  useEffect(() => {
    onUpdate({
      theme: themeOption,
      category: categoryOption,
      indicatorIds: selectedIndicators
        ? Array.from(selectedIndicators)
        : undefined,
      countries: countryOption ? countryOption : undefined,
      selectedCountryList: selectedCountryList || [],
      yearsRange,
      selectedThemeList: selectedThemeList || [],
    });
  }, [
    themeOption,
    categoryOption,
    selectedIndicators,
    selectedCountryID,
    selectedCountryList,
    yearsRange,
    onUpdate,
    selectedThemeList,
  ]);

  useEffect(() => {
    if (selectedTheme && selectedCategory) {
      getIndicators({
        variables: {
          themeId: Number(Array.from(selectedTheme).join(",")),
          indicatorCategoryId: Number(selectedCategory),
        },
      });
    }
  }, [getIndicators, selectedTheme, selectedCategory]);

  const selectedCountriesIDsArray = new Set(
    selectedCountryList?.map((item: { value: string }) => item.value)
  );

  // Transforming the Set
  const isArray = selectedThemeList || [];
  const selectedThemesIDsArray = new Set(
    [...isArray].map((item: any) => String(item.value))
  );

  const modifiedThemeOptions = [...(data?.themeOptions || [])];

  return (
    <Box>
      <ConditionalRenderer condition={isModule === 2}>
        <OptionsAccordion
          value={selectedCountryID}
          onChange={(v) => setSelectedCountryID(v)}
          options={data?.countryOptions || []}
          loading={loading}
          title={
            <AlignedRow>
              <SvgIcon
                component={CountryIcon}
                inheritViewBox
                fontSize="small"
              />
              <Typography>Country</Typography>
            </AlignedRow>
          }
          disableGutters
          square
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={isModule === 1}>
        <OptionsAccordion
          defaultExpanded
          value={selectedTheme}
          onChange={(v) => {
            setSelectedIndicators(null);
            setSelectedTheme(v);
          }}
          options={data?.themeOptions || []}
          loading={loading}
          title={
            <AlignedRow>
              <SvgIcon component={ThemeIcon} inheritViewBox fontSize="small" />
              <Typography>{translator("Theme")}</Typography>
            </AlignedRow>
          }
          disableGutters
          square
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={isModule === 1}>
        <OptionsAccordion
          multiple
          values={selectedCountriesIDsArray}
          onMultipleSelect={(selectedValues) => {
            const selectedObjects = (data?.countryOptions || []).filter(
              (option: { value: string }) => selectedValues.has(option.value)
            );
            if (selectedValues?.size === 0) {
              setSelectedCountryID("");
              setSelectedCountryList([]);
              return;
            }
            if (selectedCountryID === "") {
              setSelectedCountryID(selectedValues);
              setSelectedCountryList([...selectedObjects]);
              return;
            }

            setSelectedCountryList([...selectedObjects]);
          }}
          options={data?.countryOptions || []}
          loading={loading}
          disabled={!selectedTheme}
          title={
            <AlignedRow>
              <SvgIcon
                component={CountryIcon}
                inheritViewBox
                fontSize="small"
              />
              <Typography>{translator("Country")}</Typography>
            </AlignedRow>
          }
          disableGutters
          square
        />
      </ConditionalRenderer>
      <ConditionalRenderer condition={isModule === 2}>
        <OptionsAccordion
          multiple
          values={selectedThemesIDsArray}
          onMultipleSelect={(selectedValues) => {
            const selectedObjects = modifiedThemeOptions.filter(
              (option: { value: string }) => selectedValues.has(option.value)
            );
            if (selectedValues?.size === 0) {
              setSelectedTheme("");
              setSelectedThemeList([]);
              return;
            }
            if (selectedTheme === "") {
              setSelectedTheme(selectedValues);
              setSelectedThemeList([...selectedObjects]);
              return;
            }

            setSelectedThemeList([...selectedObjects]);
          }}
          options={modifiedThemeOptions}
          loading={loading}
          title={
            <AlignedRow>
              <SvgIcon component={ThemeIcon} inheritViewBox fontSize="small" />
              <Typography>{translator("Theme")}</Typography>
            </AlignedRow>
          }
          disableGutters
          square
        />
      </ConditionalRenderer>
      <OptionsAccordion
        value={selectedCategory}
        onChange={(v) => {
          setSelectedIndicators(null);
          setSelectedCategory(v);
        }}
        options={data?.categoryOptions || []}
        loading={loading}
        title={
          <AlignedRow>
            <SvgIcon component={CategoryIcon} inheritViewBox fontSize="small" />
            <Typography>{translator("Categories")}</Typography>
          </AlignedRow>
        }
        disableGutters
        square
      />
      <OptionsAccordion
        multiple
        values={selectedIndicators}
        onMultipleSelect={(v) => setSelectedIndicators(v)}
        options={indicators?.indicatorOptions || []}
        loading={loadingIndicatorOptions}
        disabled={!selectedCategory || !selectedTheme}
        title={
          <AlignedRow>
            <SvgIcon
              component={IndicatorIcon}
              inheritViewBox
              fontSize="small"
            />
            <Typography>{translator("Indicators")}</Typography>
          </AlignedRow>
        }
        disableGutters
        square
      />

      <TimeInputAccordion
        value={yearsRange}
        onChange={(v) => setYearRange(v)}
        title={
          <AlignedRow>
            <SvgIcon component={TimeIcon} inheritViewBox fontSize="small" />
            <Typography>{translator("Time")}</Typography>
          </AlignedRow>
        }
        disabled={!selectedCategory || !selectedTheme}
        disableGutters
        square
      />
    </Box>
  );
};

export default DataFilters;
