import type { FC } from "react";
import Select, { SelectProps } from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { FaChevronDown, FaLanguage } from "react-icons/fa6";
import { useLanguageTranslator } from "../../useLanguageContext";

export type I18nSelectProps = SelectProps & {
  options: { label: string; value: string }[];
};

const I18nSelect: FC<I18nSelectProps> = (props) => {
  const { options, children, ...selectProps } = props;

  const { setCurrentLanguage, currentLanguage } = useLanguageTranslator();

  const handleChange = (event: any) => {
    setCurrentLanguage(event.target.value);
    localStorage.setItem("lang", event.target.value);
  };

  return (
    <Select
      size="small"
      variant="standard"
      disableUnderline
      IconComponent={FaChevronDown}
      onChange={handleChange}
      value={currentLanguage}
      startAdornment={
        <InputAdornment position="start">
          <FaLanguage size={20} color="#EF8400" />
        </InputAdornment>
      }
      {...selectProps}
    >
      {options.map((op, i) => (
        <MenuItem key={`${i}`} value={op.value}>
          {op.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default I18nSelect;
