import React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Container,
  Stack,
  Paper,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableRow,
} from "@mui/material";
import TabLabel from "../../components/@common/TabLabel";
import TableHead from "../../components/@common/TableHead";
import TableBody from "../../components/@common/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import StatusTag from "../../components/@common/StatusTag";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { styled } from "@mui/material/styles";
import {
  ConditionalRenderer,
  useLanguageTranslator,
  AppBar,
  PageTitle,
  SearchBar,
} from "../../components";
import { IsNotAuthenticated } from "./isNotAuthenticated";
import { Policies } from "./policies";
import { Practices } from "./practices";
import { Peoples } from "./people";

const TableCell = styled(MuiTableCell)({
  textWrap: "nowrap",
});

const Dashboard: React.FC = () => {
  const location = useLocation();
  const isPrivate = location?.pathname?.includes("/submission/dashboard");
  const { translator } = useLanguageTranslator();

  return (
    <Box pb={4}>
      <AppBar>
        <PageTitle title={translator("Dashboard")} />
      </AppBar>
      <ConditionalRenderer condition={!isPrivate}>
        <IsNotAuthenticated />
      </ConditionalRenderer>

      <ConditionalRenderer condition={!!isPrivate}>
        {/* BEGIN: Submissions data */}
        <Container sx={{ my: 3 }}>
          <Paper component={Container} sx={{ py: 3, mb: 2 }}>
            <Box mb={2}>
              <Typography variant="h4">
                {translator("Submissions Tab")}
              </Typography>
              <Typography variant="caption">
                {translator(
                  "Categorized view of all the submissions you have made"
                )}
                .
              </Typography>
            </Box>
            <Stack
              direction={{ lg: "row" }}
              gap={2}
              justifyContent={{ lg: "space-between" }}
              alignItems={{ lg: "center" }}
            >
              <Tabs value={0} variant="scrollable">
                <Tab
                  label={
                    <TabLabel count={157}>
                      {translator("All submissions")}
                    </TabLabel>
                  }
                />
                <Tab
                  label={
                    <TabLabel count={57}>
                      {translator(`status.Approved`)}
                    </TabLabel>
                  }
                />
                <Tab
                  label={
                    <TabLabel count={50}>
                      {translator(`status.Pending`)}
                    </TabLabel>
                  }
                />
                <Tab
                  label={
                    <TabLabel count={50}>
                      {translator(`status.Rejected`)}
                    </TabLabel>
                  }
                />
              </Tabs>

              <Box display={{ xs: "none", lg: "block" }}>
                <SearchBar
                  placeholder={translator("Search your submissions")}
                />
              </Box>
            </Stack>
          </Paper>

          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>
                      {translator("dashboardTableHead.Category")}
                    </TableCell>
                    <TableCell>
                      {translator("dashboardTableHead.Name")}
                    </TableCell>
                    <TableCell>
                      {translator("dashboardTableHead.Description")}
                    </TableCell>
                    <TableCell>
                      {translator("dashboardTableHead.Status")}
                    </TableCell>
                    <TableCell>
                      {translator("dashboardTableHead.Uploaded date")}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Array(7)
                    .fill(null)
                    .map((_, i) => (
                      <TableRow key={`${i}`}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>Policy</TableCell>
                        <TableCell>Name Policy {i + 1}</TableCell>
                        <TableCell>Short description goes here</TableCell>
                        <TableCell padding="checkbox">
                          <StatusTag label="Pending" status="pending" />
                        </TableCell>
                        <TableCell align="right">18.05.2024</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Box component={Container} sx={{ py: 3 }} textAlign="right">
              <Link href="#" underline="none" color="textSecondary">
                {translator("View all")}
              </Link>
            </Box>
          </Paper>
        </Container>
        {/* END: Submissions data */}
      </ConditionalRenderer>

      {/* For Public users we enable two modules Policies and Practices */}

      <ConditionalRenderer condition={!isPrivate}>
        {/* BEGIN: List of policies */}
        <Policies />
        {/* END: List of policies */}

        {/* BEGIN: List of practices */}
        <Practices />
        {/* END: List of practices */}

        {/* BEGIN: List of people */}
        <Peoples />
        {/* END: List of people */}
      </ConditionalRenderer>
    </Box>
  );
};

export { Dashboard };
