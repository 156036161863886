import {
  Container,
  Paper,
  Stack,
  Typography,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Avatar,
  SvgIcon,
} from "@mui/material";
import { ReactComponent as FileIcon } from "../../@lib/icons/file-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../@lib/icons/chevron-right-icon.svg";
import { documentsDateConversion } from "../../utils/helpers";
import { ConditionalRenderer } from "../conditionalRenderer";
import { useLanguageTranslator } from "../useLanguageContext";

function LatestReport({ items, path, isAllShow = true }: any) {
  const { translator } = useLanguageTranslator();
  return (
    <Container sx={{ my: 3 }}>
      <ConditionalRenderer condition={isAllShow}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">
              {translator("Latest")} {translator("Reports")}
            </Typography>
            <Link
              // component={RouterLink}
              // to="viewAllReports"
              href={path}
              underline="none"
              color="textSecondary"
            >
              {translator("View all")}
            </Link>
          </Stack>
        </Paper>
      </ConditionalRenderer>

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell align="right">View</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {items?.map((elem: any, i: any) => {
                const dateCreatedAt = documentsDateConversion(elem?.createdAt);
                return (
                  <TableRow key={`${i}`}>
                    <TableCell>
                      <Box
                        sx={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <Avatar sx={{ mr: 2 }}>
                          <SvgIcon component={FileIcon} inheritViewBox />
                        </Avatar>
                        <Typography variant="h6" whiteSpace="nowrap">
                          {elem?.filename?.slice(0, 50)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{dateCreatedAt}</TableCell>
                    <TableCell align="right">
                      <a
                        href={`https://niyel-backend.testaristarc.com/${elem?.path}`}
                        target="blank"
                      >
                        <SvgIcon
                          href=""
                          target="blank"
                          component={ArrowRightIcon}
                          inheritViewBox
                        />
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}

              {items?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2}>No Data</TableCell>
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}
export { LatestReport };
