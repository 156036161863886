import React from "react";
import type { Policy } from "../../@lib/types";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
  CardProps,
  Card as MUICard,
  CardMedia,
  CardContent,
  CardActionArea,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import { forwardRef } from "react";
import { useAuthSession } from "../AuthSessionProvider";
import { styled } from "@mui/material/styles";
import imagePlaceholder from "../../@lib/images/image-placeholder.svg";
import { ConditionalRenderer } from "../conditionalRenderer";
import StatusTag from "../@common/StatusTag";

export type CardPropsT = Omit<CardProps, "id"> &
  Pick<Policy, "id" | "title" | "description" | "status" | "picture"> & {
    disabled?: boolean;
    onDelete?: (id: string | number) => void;
    isPrivate?: boolean;
    path?: string;
  };

const CardSkeleton = (
  <div>
    <Skeleton variant="rounded" height={240} sx={{ mb: 1 }} />
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 1 }}
    >
      <Skeleton variant="rounded" width="30%" />
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
      >
        <Skeleton variant="text" width="50%" height={20} />
        <Skeleton variant="text" width="30%" height={16} />
      </Box>
    </Stack>
    <Skeleton variant="text" width="40%" height={20} />
    <Skeleton variant="text" width="80%" height={16} />

    <Stack direction="row" justifyContent="space-between" sx={{ mt: 4 }}>
      <Skeleton variant="rounded" width="45%" height={24} />
      <Skeleton variant="rounded" width="45%" height={24} />
    </Stack>
  </div>
);

export { CardSkeleton };

const CardActionButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  borderRadius: 100,
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  fontSize: 12,
  flex: 1,
  color: "#ffffff",
}));

const ForwardedCard: React.ForwardRefRenderFunction<HTMLDivElement, any> = (
  props,
  ref
) => {
  const {
    isPrivate = false,
    isAdmin = false,
    id,
    title,
    description,
    picture,
    status,
    disabled = false,
    path = "",
    editPath = "",
    onDelete = () => {},
    onAdminActions = () => {},
    ...cardProps
  } = props;

  const { user } = useAuthSession();
  const isLoggedIn = !!user;

  return (
    <MUICard {...cardProps} ref={ref}>
      <CardActionArea component={RouterLink} to={path} disabled={disabled}>
        <CardMedia
          component="img"
          height={280}
          image={picture || imagePlaceholder}
          alt="policy papers"
        />
      </CardActionArea>
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ConditionalRenderer condition={isAdmin}>
              <StatusTag label="Approved" status="approved" />
            </ConditionalRenderer>
            <Typography variant="h5" mt={1}>
              {title?.slice(0, 27)}...
            </Typography>
          </div>

          <div style={{ textAlign: "right" }}>
            <Typography variant="caption">17.05.2024</Typography>
          </div>
        </Stack>
        <Typography component="p" variant="caption" noWrap>
          {description}
        </Typography>
        <ConditionalRenderer condition={isAdmin}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0px 0px 0px",
            }}
          >
            <div style={{ width: "110px", height: "36px" }}>
              <CardActionButton
                size="small"
                variant="contained"
                style={{
                  backgroundColor: "#FF6D6D",
                  width: "110px",
                  height: "36px",
                  borderRadius: "10px",
                }}
                onClick={() => onAdminActions && onAdminActions(id, "rejected")}
              >
                Reject
              </CardActionButton>
              {/* <CardActionButton
                size="small"
                variant="contained"
                style={{
                  backgroundColor: "#52D777",
                  width: "110px",
                  height: "36px",
                  borderRadius: "10px",
                }}
                onClick={() => onAdminActions && onAdminActions(id, "approve")}
              >
                Approve
              </CardActionButton> */}
            </div>
          </div>
        </ConditionalRenderer>
      </CardContent>
      <ConditionalRenderer
        condition={isPrivate && isLoggedIn && status !== "approved"}
      >
        <Stack direction="row" spacing={1} sx={{ p: 2, width: "100%" }}>
          <CardActionButton
            size="small"
            variant="contained"
            color="warning"
            disabled={disabled}
            href={editPath}
          >
            Edit
          </CardActionButton>
          <CardActionButton
            size="small"
            variant="contained"
            color="error"
            disabled={disabled}
            // onClick={() => onDelete && onDelete(id)}
          >
            DELETE
          </CardActionButton>
        </Stack>
      </ConditionalRenderer>
    </MUICard>
  );
};

const Card = forwardRef(ForwardedCard);

export { Card };
