import { useQuery, gql } from "@apollo/client";
import { Practice } from "../../@lib/types";

type PracticesCollectionData = {
  practices: Practice[];
};

const fetchPracticesDocuments = gql`
  query ($limit: Int) {
    practiceDocuments(limit: $limit) {
      collection {
        createdAt
        filename
        id
        path
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const usePracticesDocumentsCall = ({ variables }: any) => {
  const { data, loading } = useQuery<PracticesCollectionData>(
    fetchPracticesDocuments,
    {
      fetchPolicy: "cache-and-network",
      variables: variables,
    }
  ) as any;

  return {
    practiceDocuments: data?.practiceDocuments?.collection,
    loading,
  };
};

export { usePracticesDocumentsCall };
