import { Grid, SelectChangeEvent, Typography } from "@mui/material";
import {
  SingleInputDateRangePicker,
  SelectInput,
} from "../../../../components";

function Filters() {
  const handleChange = (event: SelectChangeEvent) => {};
  const selectListItems = [
    {
      value: "Senegal",
    },
    {
      value: "Theme",
    },
    {
      value: "Senegala",
    },
  ];
  return (
    <div className="flex">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={2}>
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <Typography variant="h1" fontSize={15} color="#989c9f">
              Filter by:
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SelectInput
            label=""
            value="Senegal"
            styling={{
              border: "0px",
              borderRadius: "30px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              outline: "0px",
            }}
            listItems={selectListItems}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <SingleInputDateRangePicker
            styling={{
              border: "0px",
              borderRadius: "30px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              outline: "0px",
              color: "black",
              width: "100%",
              height: "48px",
              "&:hover": {
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SelectInput
            label=""
            value="Theme"
            styling={{
              border: "0px",
              borderRadius: "30px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              outline: "0px",
            }}
            listItems={selectListItems}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export { Filters };
