import { Button, Divider, Stack, Typography } from "@mui/material";
import { PDFDocIcon } from "../../../@lib/icons";
import CoverImage from "../../../components/@common/CoverImage";
import imagePlaceholder from "../../../@lib/images/image-placeholder.svg";
import StatusTag from "../../../components/@common/StatusTag";

function LeftSide({ data }: any) {
  return (
    <>
      <CoverImage
        image={data?.picture || imagePlaceholder}
        height={400}
        mb={2}
      />
      <StatusTag
        label={data?.status?.charAt(0)?.toUpperCase() + data?.status?.slice(1)}
        status={data?.status}
      />
      <Typography variant="h5" mt={1}>
        {data?.title}
      </Typography>
      <Typography component="p" variant="caption">
        {data?.description}
      </Typography>
      <Stack
        sx={{
          my: 2,
          p: 1,
          border: "1px solid #F1F1F1",
          borderRadius: "10px",
        }}
        direction={{ lg: "row" }}
        alignItems={{ lg: "center" }}
        justifyContent={{ lg: "space-between" }}
      >
        <div>
          <Stack
            sx={{
              backgroundColor: "#fff2f2",
              width: "90px",
              height: "82px",
              position: "relative",
              zIndex: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ position: "absolute", zIndex: "1000" }}>
              <PDFDocIcon />
            </div>
          </Stack>
        </div>
        <Stack direction={{ lg: "column" }} sx={{ mx: 1 }}>
          <Typography
            variant="h4"
            fontWeight={600}
            color={"#494E54"}
            fontSize={"15px"}
            mt={2}
            gutterBottom
          >
            Document File Name.pdf
          </Typography>
          <Typography
            variant="h4"
            fontWeight={400}
            fontSize={"11px"}
            color={"#494E54"}
            gutterBottom
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Typography>
        </Stack>
        <Stack
          direction={{ lg: "column" }}
          justifyContent={{ lg: "space-between" }}
        >
          <Button
            sx={{
              px: 6,
              backgroundColor: "#F6A000",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "primary.light",
              },
            }}
          >
            View
          </Button>
          <Button
            sx={{
              px: 6,
              mt: 1,
              backgroundColor: "#00AAB0",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "primary.light",
              },
            }}
          >
            Download
          </Button>
        </Stack>
      </Stack>
      <Divider
        component="div"
        role="presentation"
        sx={{
          border: "1px solid #F1F1F1",
        }}
      />
    </>
  );
}

export { LeftSide };
