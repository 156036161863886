import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiSelect from "@mui/material/Select";
import { gql } from "@apollo/client";
import { YearRangeSelector } from "../input";
import { ConditionalRenderer } from "../conditionalRenderer";
import { useApolloQuery } from "../../hooks";

const Select = styled(MuiSelect)(({ theme }) => ({
  borderRadius: 100,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[4],
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const policyFormOptionsQueryDocument = gql`
  query policyFormOptionsQuery {
    countryOptions: countries {
      value: id
      label: name
    }
    themeOptions: themes {
      value: id
      label: title
    }
  }
`;

const geographicalAreasQuery = gql`
  query {
    geographicalAreas {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

function Filters({ setFilters, filters, isPublicPolicies = false }: any) {
  const { data } = useApolloQuery({
    queryDocument: policyFormOptionsQueryDocument,
  });

  const { data: geographicalAreaList } = useApolloQuery({
    queryDocument: geographicalAreasQuery,
  });

  const modifiedGeographicalAreaList =
    geographicalAreaList?.geographicalAreas?.map(
      (item: { id: string; name: string }) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      }
    ) || [];

  const handleChange = (event: any) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  return (
    <Container sx={{ my: 5 }}>
      <Stack direction="row" gap={2} alignItems="center">
        <Typography variant="body2" color="textSecondary">
          Filter by:
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Country</InputLabel>
            <Select label="Country" name="country" onChange={handleChange}>
              {data?.countryOptions.map((op: any, i: any) => (
                <MenuItem key={`${op.value}-${i}`} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <YearRangeSelector setFilters={setFilters} filters={filters} />
          </FormControl>

          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>Theme</InputLabel>
            <Select label="Theme" name="theme" onChange={handleChange}>
              {data?.themeOptions.map((op: any, i: any) => (
                <MenuItem key={`${op.value}-${i}`} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ConditionalRenderer condition={isPublicPolicies}>
            <FormControl sx={{ minWidth: 180 }}>
              <InputLabel>Geographical Area</InputLabel>
              <Select
                label="Geographical Area"
                name="geographicalArea"
                onChange={handleChange}
              >
                {modifiedGeographicalAreaList.map((op: any, i: any) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </ConditionalRenderer>
        </Stack>
      </Stack>
    </Container>
  );
}
export { Filters };
