import React from "react";
import { Controller } from "react-hook-form";
import { useState, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { OptionType, IDType } from "../../@lib/types";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { Attachment } from "..";

export type PolicyInfoFormProps = {
  countryOptions: OptionType<IDType>[];
  themeOptions: OptionType<IDType>[];
  geographicalAreaOptions: (OptionType<IDType> & {
    policyCategoryOptions: OptionType<IDType>[];
  })[];
  disabled?: boolean;
};

const PolicyInfoForm: React.FC<PolicyInfoFormProps> = (props) => {
  const { disabled, geographicalAreaOptions } = props;

  const [imageAttachmentDetails, setImageAttachmentDetails] = useState({
    name: "",
  });
  const [documentAttachmentDetails, setDocumentAttachmentDetails] = useState({
    name: "",
  });

  const { trigger, setValue, getValues } = useFormContext();
  const [selectedGeoZoneId, setSelectedGeoZoneId] = useState("");

  const categoryOptions = useMemo(() => {
    const geoZone = geographicalAreaOptions.find(
      (op) => op.value === selectedGeoZoneId
    );
    return geoZone?.policyCategoryOptions || [];
  }, [selectedGeoZoneId, geographicalAreaOptions]);

  return (
    <Stack direction={{ xs: "column-reverse", md: "row" }} gap={3}>
      <Stack flex={1} gap={2}>
        <FormControl fullWidth>
          <FormLabel>Upload Image</FormLabel>
          <Attachment
            allowedFormats={["svg", "png", "jpg", "gif", "jpeg"]}
            text="SVG, PNG, JPG or GIF (max. 800x400px)"
            setAttachmentDetails={setImageAttachmentDetails}
            attachmentDetails={imageAttachmentDetails}
            variant={{ variant: "outlined", py: 10 }}
          />
        </FormControl>

        <Controller
          name="countryId"
          rules={{ required: "Required!" }}
          render={({ field, fieldState: { error, invalid } }) => (
            <FormControl fullWidth required error={invalid} disabled={disabled}>
              <FormLabel>Country</FormLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled sx={{ color: "text.secondary" }}>
                  <em>Select country</em>
                </MenuItem>
                {props.countryOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="themeId"
          rules={{ required: "Required!" }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Theme</FormLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled sx={{ color: "text.secondary" }}>
                  <em>Select theme</em>
                </MenuItem>
                {props.themeOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="geographicalAreaId"
          rules={{ required: "Required!" }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Geographical Zone</FormLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedGeoZoneId(getValues(field.name));
                  setValue("policyCategoryId", "", { shouldValidate: true });
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled sx={{ color: "text.secondary" }}>
                  Select geographical zone
                </MenuItem>
                {props.geographicalAreaOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="policyCategoryId"
          rules={{ required: "Required!" }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth required error={!!error} disabled={disabled}>
              <FormLabel>Category</FormLabel>
              <Select
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(field.name);
                }}
                displayEmpty
              >
                <MenuItem value="" disabled sx={{ color: "text.secondary" }}>
                  <em>Select category</em>
                </MenuItem>
                {categoryOptions.map((op: any, i: any) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>
                    {op.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Stack>

      <Stack flex={1} gap={2}>
        <Controller
          name="title"
          rules={{ required: "Required!" }}
          render={({ field, fieldState: { error } }) => (
            <FormControl required error={!!error} disabled={disabled}>
              <FormLabel>Policy Name</FormLabel>
              <OutlinedInput
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(field.name);
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="description"
          rules={{ required: "Required!" }}
          render={({ field, fieldState: { error } }) => (
            <FormControl required error={!!error} disabled={disabled}>
              <FormLabel>Policy Description</FormLabel>
              <OutlinedInput
                multiline
                rows={3}
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(field.name);
                }}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="date"
          render={({ field }) => (
            <FormControl disabled={disabled}>
              <FormLabel>Date voted</FormLabel>
              <OutlinedInput {...field} />
            </FormControl>
          )}
        />

        <FormControl fullWidth>
          <FormLabel>Upload document</FormLabel>
          <Attachment
            allowedFormats={["pdf"]}
            text="PDF file (max. 50mb)"
            setAttachmentDetails={setDocumentAttachmentDetails}
            attachmentDetails={documentAttachmentDetails}
            variant={{ variant: "outlined" }}
          />
        </FormControl>

        <Controller
          name="link"
          render={({ field }) => (
            <FormControl disabled={disabled}>
              <FormLabel>External policy link</FormLabel>
              <OutlinedInput {...field} />
            </FormControl>
          )}
        />
      </Stack>
    </Stack>
  );
};

export default PolicyInfoForm;
