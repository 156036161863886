import { gql } from "@apollo/client";
import { Header } from "../../../components";
import { AdminView } from "../../../features";
import { useApolloQuery } from "../../../hooks";

const policyDocument = gql`
  query ($limit: Int, $page: Int) {
    pendingPolicies(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        geographicalArea {
          name
        }
        id
        link
        picture
        policyCategory {
          name
        }
        status
        theme {
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const noPendingDocument = gql`
  query ($limit: Int, $page: Int) {
    noPendingPolicies(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        geographicalArea {
          name
        }
        id
        link
        picture
        policyCategory {
          name
        }
        status
        theme {
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

function AdminPolicies() {
  const { data: pending } = useApolloQuery({
    queryDocument: policyDocument,
    isPrivateCall: true,
    variables: {
      limit: 8,
    },
  });

  const {
    data: noPending,
    loading,
    refetch,
  } = useApolloQuery({
    queryDocument: noPendingDocument,
    isPrivateCall: true,
    variables: {
      limit: 8,
    },
  });

  return (
    <>
      <Header
        title="Admin Area"
        subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX"
      />
      <AdminView
        pendingData={pending?.pendingPolicies?.collection || []}
        noPendingData={noPending?.noPendingPolicies?.collection || []}
        title="Policies"
        viewPath="/admin/policies/"
        viewAllSubmissionsPath="/admin/policies/view_all"
      />
    </>
  );
}

export { AdminPolicies };
