import {
  Modal as MUIModal,
  Typography,
  Button,
  Box,
  Stack,
} from "@mui/material";
import { ModalCloseIcon, RightArrowIcon } from "../../@lib/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  py: 3,
  px: 1,
};

function Modal({ isOpen, setIsOpen }: any) {
  const handleClose = () =>
    setIsOpen({ status: false, currId: null, module: "" });

  return (
    <div>
      <MUIModal
        open={isOpen?.status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}
          >
            <Button
              onClick={handleClose}
              sx={{ padding: "0px", minWidth: "auto" }}
            >
              <ModalCloseIcon />
            </Button>
          </Stack>
          <Stack
            direction="column"
            sx={{ padding: "10px 60px" }}
            alignItems="center"
          >
            <Typography id="modal-modal-title" sx={{ fontSize: "16px" }}>
              This policy has already been rejected.
            </Typography>
            <Typography
              id="modal-modal-description"
              variant="h6"
              component="h2"
              sx={{ fontSize: "16px" }}
            >
              Do you want to change its status to Approved?
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="center" gap={1} mt={2}>
            <Button
              sx={{
                backgroundColor:
                  isOpen?.module === "rejected" ? "#FF6D6D" : "#52D777",
                color: "white",
                padding: "7px 50px",
              }}
            >
              {isOpen?.module === "rejected" ? "Reject" : "Approve"}
            </Button>
            <Button
              sx={{
                backgroundColor: "#989C9F",
                color: "white",
                padding: "7px 50px",
              }}
            >
              Cancel
            </Button>
          </Stack>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              color: "#F6A000",
              fontSize: "14px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Review again policy details{" "}
            <div style={{ marginLeft: "10px" }}>
              <RightArrowIcon />
            </div>
          </Typography>
        </Box>
      </MUIModal>
    </div>
  );
}

export { Modal };
