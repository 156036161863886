import { useState, useCallback } from "react";
import React from "react";
import { useMutation, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  Slide,
  Fade,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { useAuthSession } from "../../../components/AuthSessionProvider";
import { useNotify } from "../../../components/@common/NotificationsProvider";
import {
  CTAContainer,
  Card,
  CardSkeleton,
  ConditionalRenderer,
  Header,
  LatestReport,
  TabsFilters,
  useLanguageTranslator,
} from "../../../components";
import { Attachment } from "../../../features";
import { usePracticesDocumentsCall, usePrivatePracticesCall } from "../../../hooks";
import type { Practice } from "../../../@lib/types";

const deletePracticeMutationDocument = gql`
  mutation DeletePractice($practiceInput: PracticeDeleteInput!) {
    practiceDelete(input: $practiceInput) {
      practice {
        id
      }
    }
  }
`;

const PrivatePractices: React.FC = () => {
  const { practiceDocuments } = usePracticesDocumentsCall({
    variables: { limit: 8 },
  }) as any;
  const [attachmentDetails, setAttachmentDetails] = useState({ name: "" });
  const notify = useNotify();
  const navigate = useNavigate();
  const { user } = useAuthSession();
  const isLoggedIn = !!user;
  const [deletePractice, { loading: deletingPractice }] = useMutation(
    deletePracticeMutationDocument
  );
  const [currTabIndex, setCurrTabIndex] = useState(0);
  const { practices, dist, loading } = usePrivatePracticesCall(
    user,
    currTabIndex
  );

  const { translator } = useLanguageTranslator();

  /**
   * handleDeletePractice()
   */
  const handleDeletePractice = useCallback(
    async (practice: Practice) => {
      if (
        await notify.confirm(
          `Deleting practice "${practice.title}". This cannot be undone.`
        )
      ) {
        try {
          await deletePractice({
            variables: {
              practiceInput: {
                id: practice.id,
              },
            },
          });

          notify.success(`Practice "${practice.title}" deleted successfuly!`);
        } catch (err) {
          notify.error(err as Error);
        }
      }
    },
    [deletePractice, notify]
  );

  /**
   * handleEditPractice()
   */
  const handleEditPractice = useCallback(
    (practice: Practice) => navigate(`/practices/${practice.id}/edit`),
    [navigate]
  );

  const privateTab = [
    {
      value: "all",
      count: dist?.all?.length,
      title: "All",
    },
    {
      value: "approved",
      count: dist.approved.length,
      title: translator("status.Approved"),
    },
    {
      value: "pending",
      count: dist.pending.length,
      title: translator("status.Pending"),
    },
    {
      value: "rejected",
      count: dist.rejected.length,
      title: translator("status.Rejected"),
    },
  ];

  return (
    <Box pb={4}>
      <Header
        title={translator("Practices")}
        subtitle="Lorem ipsum dolor sit amet."
      />
      <Container sx={{ my: 3 }}>
        <TabsFilters
          dist={dist}
          currTab={currTabIndex}
          setCurrTab={setCurrTabIndex}
          listItems={privateTab}
          heading={`${translator("List of")} ${translator("Practices")}`}
        />

        <Grid container spacing={2}>
          {practices.map((p, i) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card
                  {...p}
                  path={`/practices/${p?.id}`}
                  editPath={`/practices/${p.id}/edit`}
                  disabled={loading || deletingPractice}
                  onDelete={handleDeletePractice}
                  onEdit={handleEditPractice}
                  sx={{ height: "100%" }}
                  isLoggedIn={isLoggedIn}
                  isPrivate
                />
              </Slide>
            </Grid>
          ))}

          {loading &&
            practices.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {CardSkeleton}
                  </Grid>
                </Fade>
              ))}
        </Grid>
      </Container>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <Fade in timeout={800}>
            <CTAContainer sx={{ textAlign: "center" }}>
              <Typography variant="subtitle1" mb={2}>
                {translator("addNewItemToPlatform", {
                  item: translator("Practices"),
                })}
              </Typography>
              <Button
                component={RouterLink}
                to="new"
                sx={{
                  px: 6,
                  backgroundColor: "#ffffff",
                  color: "primary.dark",
                  "&:hover": {
                    backgroundColor: "primary.light",
                  },
                }}
              >
                {translator("generic.CREATE")}
              </Button>
            </CTAContainer>
          </Fade>
        </Container>
      )}

      <LatestReport
        items={practiceDocuments}
        path="/practices/viewAllReports"
      />

      <ConditionalRenderer condition={isLoggedIn}>
        <Container sx={{ my: 3 }}>
          <Paper component={Container} sx={{ py: 3, mb: 2 }}>
            <Typography variant="h4">
              {translator("generic.Data")} {translator("generic.Import")}
            </Typography>
          </Paper>

          <Attachment
            allowedFormats={["pdf"]}
            text="PDF file (max. 50mb)"
            setAttachmentDetails={setAttachmentDetails}
            attachmentDetails={attachmentDetails}
          />
          <Box textAlign="right">
            <Button variant="contained" sx={{ px: 6 }} disabled>
              {translator("generic.Import")}
            </Button>
          </Box>
        </Container>
      </ConditionalRenderer>
    </Box>
  );
};

export { PrivatePractices };
