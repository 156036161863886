import { Grid, Typography } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { Filters } from "./filters";
import { AreaCharts } from "../../../components";

function IsNotAuthenticated() {
  return (
    <Fragment>
      <div style={{ padding: "0px 20px" }}>
        <Grid
          container
          px={4}
          my={2}
          py={3}
          sx={{ backgroundColor: "#ffffff", borderRadius: "10px" }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h1" fontSize={18} sx={{ fontWeight: "bold" }}>
              Composite Policy Index
            </Typography>
            <Typography variant="h2" fontSize={12} fontWeight={200} mt={1}>
              Compare spending over time
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Filters />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            lg={12}
            sx={{ backgroundColor: "white", borderRadius: "10px" }}
            py={5}
          >
            <div style={{ height: "35dvh", width: "93%", margin: "20px 20px" }}>
              <AreaCharts />
            </div>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
}

export { IsNotAuthenticated };
