import { useMemo, useState } from "react";
import { Container, Stack, Paper, Typography, Button } from "@mui/material";

import { Link as RouterLink, useLocation } from "react-router-dom";

import { useAuthSession } from "../../../components/AuthSessionProvider";
import { PeopleListCard } from "../components";
import {
  Filters,
  Header,
  ConditionalRenderer,
  TabsFilters,
  CTAContainer,
} from "../../../components";
import { usePeoplesCall } from "../../../hooks";
import { useFilters } from "../helpers";

function PeopleList() {
  const [filters, setFilters] = useState({
    country: "",
    startYear: "",
    endYear: "",
    theme: "",
  });
  const [currTab, setCurrTab] = useState("all");
  const [pageNumber, setPageNumber] = useState(1);

  const { user } = useAuthSession();
  const isLoggedIn = !!user;

  const location = useLocation();
  const isPrivate = location?.pathname?.includes("/submission/people") || false;

  const { data } = usePeoplesCall({
    isPrivate,
    user,
    variables: {
      limit: 8,
      page: pageNumber,
      ...(filters?.country ? { countryId: Number(filters?.country) } : {}),
      ...(filters?.theme ? { themeId: Number(filters?.theme) } : {}),
      ...(filters?.startYear ? { startYear: Number(filters?.startYear) } : {}),
      ...(filters?.endYear ? { endYear: Number(filters?.endYear) } : {}),
    },
  });

  const { tabsData, people = [] }: any = useFilters({ isPrivate, data });

  const filteredDataResults = useMemo(() => {
    if (currTab === "all") return people;
    return people?.filter(
      (item: any) => item.status?.toLowerCase() === currTab
    );
  }, [people, currTab]);

  const modifiedData = isPrivate
    ? filteredDataResults
    : data?.people?.collection;

  function onClickHandler() {
    if (pageNumber === data?.people?.metadata?.totalPages) return;
    setPageNumber(pageNumber + 1);
  }

  const hasMore =
    pageNumber === data?.people?.metadata?.totalPages ||
    !data?.people?.metadata?.totalPages;

  return (
    <div>
      <Header
        title="People"
        subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX"
      />

      <Container sx={{ my: 3 }}>
        <ConditionalRenderer condition={isPrivate}>
          <TabsFilters
            currTab={currTab}
            setCurrTab={setCurrTab}
            listItems={tabsData}
            heading="List of Peoples data"
            isAllShow={isPrivate ? true : false}
          />
        </ConditionalRenderer>
        <ConditionalRenderer condition={!isPrivate}>
          <Filters setFilters={setFilters} filters={filters} />
        </ConditionalRenderer>
        <Stack gap={2}>
          {modifiedData?.map((elem: any, i: any) => (
            <PeopleListCard
              key={`people-list-card-${elem.id}`}
              elem={elem}
              isPrivate={isPrivate}
            />
          ))}
        </Stack>
      </Container>

      <ConditionalRenderer condition={!hasMore}>
        <Container sx={{ my: 3 }}>
          <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button onClick={onClickHandler}>Show more</Button>
          </Paper>
        </Container>
      </ConditionalRenderer>

      <ConditionalRenderer condition={isLoggedIn && isPrivate}>
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" mb={2}>
              Add new people to the platform
            </Typography>
            <Button
              component={RouterLink}
              to="/people/new"
              sx={{
                px: 6,
                backgroundColor: "#ffffff",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              Add People
            </Button>
          </CTAContainer>
        </Container>
      </ConditionalRenderer>
    </div>
  );
}

export { PeopleList };
