import React from "react";
import { Link as RouterLink } from "react-router-dom";

import type { Practice } from "../../../@lib/types";
import { Container, Button, Stack, Paper, Typography } from "@mui/material";
import {
  AppBar,
  PageTitle,
  CTAContainer,
  SearchBar,
  CardAll,
  ConditionalRenderer,
  TabsFilters,
} from "../../../components";

import { useState, useCallback } from "react";
import { useAuthSession } from "../../../components/AuthSessionProvider";
import { useMutation, gql } from "@apollo/client";
import { useNotify } from "../../../components/@common/NotificationsProvider";
import { usePrivatePracticesCall } from "../../../hooks/usePrivatePracticesCall";

const deletePracticeMutationDocument = gql`
  mutation DeletePractice($practiceInput: PracticeDeleteInput!) {
    practiceDelete(input: $practiceInput) {
      practice {
        id
      }
    }
  }
`;

const PrivatePracticesList: React.FC = () => {
  const notify = useNotify();
  const { user } = useAuthSession();
  const isLoggedIn = !!user;

  const [pageNumber, setPageNumber] = useState(1);

  const [deletePractice, { loading: deletingPractice }] = useMutation(
    deletePracticeMutationDocument
  );
  const [currTabIndex, setCurrTabIndex] = useState(0);
  const { practices, dist, loading, metadata } = usePrivatePracticesCall(
    user,
    currTabIndex,
    { limit: 8, page: pageNumber }
  );

  function onClickHandler() {
    if (pageNumber === metadata?.totalPages) return;
    setPageNumber(pageNumber + 1);
  }

  /**
   * handleDeletePractice()
   */
  const handleDeletePractice = useCallback(
    async (practice: Practice) => {
      if (
        await notify.confirm(
          `Deleting practice "${practice.title}". This cannot be undone.`
        )
      ) {
        try {
          await deletePractice({
            variables: {
              practiceInput: {
                id: practice.id,
              },
            },
          });

          notify.success(`Practice "${practice.title}" deleted successfuly!`);
        } catch (err) {
          notify.error(err as Error);
        }
      }
    },
    [deletePractice, notify]
  );

  const privateTab = [
    {
      value: "all",
      count: dist?.all?.length,
      title: "All",
    },
    {
      value: "approved",
      count: dist.approved.length,
      title: "Approved",
    },
    {
      value: "pending",
      count: dist.pending.length,
      title: "Pending",
    },
    {
      value: "rejected",
      count: dist.rejected.length,
      title: "Rejected",
    },
  ];

  const hasMore = pageNumber === metadata?.totalPages;

  return (
    <div>
      <AppBar>
        <Stack
          direction="row"
          flexGrow={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <PageTitle
            title="Practices"
            subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
          />

          <SearchBar placeholder="Search tree and keyword" />
        </Stack>
      </AppBar>

      <Container sx={{ my: 3 }}>
        <TabsFilters
          dist={dist}
          currTab={currTabIndex}
          setCurrTab={setCurrTabIndex}
          listItems={privateTab}
          heading="List of practices data"
          isAllShow={true}
        />
        <Stack gap={2}>
          {practices.map((p, i) => (
            <CardAll
              key={`private-practice-list-card-${i}`}
              p={p}
              loading={loading}
              isLoggedIn={isLoggedIn}
              editPath={`/practices/${p?.id}/edit`}
              handleDeletePractice={handleDeletePractice}
              path={`/practices/${p.id}`}
            />
          ))}
        </Stack>
      </Container>

      <ConditionalRenderer condition={!hasMore}>
        <Container sx={{ my: 3 }}>
          <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button onClick={onClickHandler}>Show more</Button>
          </Paper>
        </Container>
      </ConditionalRenderer>

      {isLoggedIn && (
        <Container sx={{ my: 3 }}>
          <CTAContainer sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" mb={2}>
              Add new practices to the platform
            </Typography>
            <Button
              component={RouterLink}
              to="/practices/new"
              sx={{
                px: 6,
                backgroundColor: "#ffffff",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              CREATE
            </Button>
          </CTAContainer>
        </Container>
      )}
    </div>
  );
};

export { PrivatePracticesList };
