
import { createContext } from 'react';
import { User } from '../../@lib/services/api/auth';

export type AuthSession = {
  user?: User | null;
  loginUser: (email:string, password:string) => void;
  logoutUser: () => void;
};

const authSessionContext = createContext<AuthSession>({
  user: null,
  loginUser: () => void 0,
  logoutUser: () => void 0
});

export default authSessionContext;
