import { useState, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useMutation, gql } from "@apollo/client";
import {
  Button,
  Slide,
  Fade,
  Typography,
  Paper,
  Grid,
  Container,
  Box,
} from "@mui/material";
import { Policy } from "../../../@lib/types";
import { useNotify } from "../../../components/@common/NotificationsProvider";
import { useAuthSession } from "../../../components/AuthSessionProvider";
import {
  Card,
  CardSkeleton,
  Header,
  LatestReport,
  TabsFilters,
  useLanguageTranslator,
  CTAContainer
} from "../../../components";
import { Attachment } from "../../../features";
import {
  usePoliciesDocumentsCall,
  usePrivatePoliciesCall,
} from "../../../hooks";

const policyDeleteMutationDocument = gql`
  mutation policyDeleteMutation($policy: PolicyDeleteInput!) {
    policyDelete(input: $policy) {
      policy {
        id
      }
    }
  }
`;

function PrivatePolicies() {
  const { policyDocuments } = usePoliciesDocumentsCall({
    variables: { limit: 8 },
  }) as any;
  const { user } = useAuthSession();
  const [currTab, setCurrTab] = useState<"all">("all");
  const {
    policies = [],
    dist,
    loading,
    refetch,
  } = usePrivatePoliciesCall({
    user,
    policyStatus: currTab,
    variables: { limit: 8 },
  });
  const [attachmentDetails, setAttachmentDetails] = useState({ name: "" });
  const notify = useNotify();
  const { translator } = useLanguageTranslator();

  const [deletePolicy, { loading: deletingPolicy }] = useMutation(
    policyDeleteMutationDocument
  );

  /**
   * handleDeletePolicy()
   */
  const handleDeletePolicy = useCallback(
    async (policy: Pick<Policy, "id" | "title">) => {
      if (
        await notify.confirm(
          `Deleting policy "${policy.title}". This cannot be undone.`
        )
      ) {
        try {
          await deletePolicy({ variables: { policy: { id: policy.id } } });
          refetch();
        } catch (err) {
          notify.error(err as Error);
        }
      }
    },
    [deletePolicy, refetch, notify]
  );

  const privateTab = [
    {
      value: "all",
      count: dist.all.length,
      title: "All",
    },
    {
      value: "approved",
      count: dist.approved.length,
      title: translator("status.Approved"),
    },
    {
      value: "pending",
      count: dist.pending.length,
      title: translator("status.Pending"),
    },
    {
      value: "rejected",
      count: dist.rejected.length,
      title: translator("status.Rejected"),
    },
  ];

  return (
    <Box pb={4}>
      <Header
        title={translator("Policies")}
        subtitle="Lorem ipsum dolor sit amet."
      />

      <Container sx={{ my: 3 }}>
        <TabsFilters
          dist={dist}
          currTab={currTab}
          setCurrTab={setCurrTab}
          listItems={privateTab}
          heading={`${translator("List of")} ${translator("Policies")}`}
        />
        <Grid container spacing={2}>
          {policies?.map((p: any, i: any) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card
                  {...p}
                  path={`/policies/${p?.id}`}
                  editPath={`/policies/${p?.id}/edit`}
                  disabled={deletingPolicy}
                  onDelete={() => handleDeletePolicy(p)}
                  sx={{ height: "100%" }}
                  isPrivate={true}
                />
              </Slide>
            </Grid>
          ))}

          {loading &&
            policies.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {CardSkeleton}
                  </Grid>
                </Fade>
              ))}
        </Grid>
      </Container>

      <Container sx={{ my: 3 }}>
        <Fade in timeout={800}>
          <CTAContainer sx={{ textAlign: "center" }}>
            <Typography variant="subtitle1" mb={2}>
              {translator("addNewItemToPlatform", {
                item: translator("Policies"),
              })}
            </Typography>
            <Button
              component={RouterLink}
              to="new"
              sx={{
                px: 6,
                backgroundColor: "#ffffff",
                color: "primary.dark",
                "&:hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              {translator("generic.CREATE")}
            </Button>
          </CTAContainer>
        </Fade>
      </Container>

      <LatestReport items={policyDocuments} path="/policies/viewAllReports" />

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Typography variant="h4">
            {translator("generic.Data")} {translator("generic.Import")}
          </Typography>
        </Paper>

        <Attachment
          allowedFormats={["pdf"]}
          text="PDF file (max. 50mb)"
          setAttachmentDetails={setAttachmentDetails}
          attachmentDetails={attachmentDetails}
        />
        <Box textAlign="right">
          <Button variant="contained" sx={{ px: 6 }} disabled>
            {translator("generic.Import")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export { PrivatePolicies };
