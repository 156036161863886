import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DownArrowIcon } from "../../../@lib/icons";
import "./styles.css";

function YearRangeSelector({ setFilters, filters }: any) {
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [isFloatLabel, setIsFloatLabel] = useState(false);

  const handleChange = ([newStartDate, newEndDate]: any) => {
    const startDateYear = new Date(newStartDate)?.getFullYear();
    const endDateYear = new Date(newEndDate)?.getFullYear();
    setFilters({
      ...filters,
      startYear: startDateYear?.toString(),
      endYear: endDateYear?.toString(),
    });
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <div className="yearRangeSelectorParent">
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        dateFormat="yyyy"
        showYearPicker
        onInputClick={() => setIsFloatLabel(true)}
        onClickOutside={() => setIsFloatLabel(false)}
      />
      <label
        className={isFloatLabel ? "floatYearRangeLabel" : "yearRangeLabel"}
      >
        Period
      </label>
      <div
        style={{
          position: "absolute",
          right: 13,
          top:0,
          display:'flex',
          alignItems:"center",
          height:"100%",
          transition: "transform 0.3s ease",
          transform: isFloatLabel ? "rotate(180deg)" : "rotate(0deg)",
        }}
      >
        <DownArrowIcon />
      </div>
    </div>
  );
}

export { YearRangeSelector };
