function DownArrowIcon() {
  return (
    <svg
      fill="#757575"
      height="10px"
      width="10px"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 386.257 386.257"
      xmlSpace="preserve"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <polygon points="0,96.879 193.129,289.379 386.257,96.879 "></polygon>{" "}
      </g>
    </svg>
  );
}

export { DownArrowIcon };
