import { Avatar, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const Tag = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "status",
})<any>(({ theme, status }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textTransform: "capitalize",
  ...(status === "display" && {
    color: "#00AAB0",
    backgroundColor: "#eff1f7",
    borderColor: "#00AAB0",
    border: "1px solid",
  }),
}));

function RightSide({ data }: any) {
  return (
    <>
      <Typography
        variant="h4"
        fontWeight={300}
        color={"#494E54"}
        fontSize={"12px"}
        gutterBottom
      >
        Submitted by:
      </Typography>
      <Stack direction={{ lg: "row" }} alignItems={{ lg: "center" }} gap={2}>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        <Typography
          variant="h4"
          fontWeight={600}
          fontSize={"15px"}
          color={"#494E54"}
          gutterBottom
        >
          Name Last Name
        </Typography>
      </Stack>
      <Typography
        variant="h4"
        fontWeight={300}
        color={"#494E54"}
        fontSize={"12px"}
        mt={2}
        gutterBottom
      >
        Date & Time:
      </Typography>
      <Typography
        variant="h4"
        fontWeight={600}
        fontSize={"15px"}
        color={"#494E54"}
        gutterBottom
      >
        {data?.date}
      </Typography>
      <Typography
        variant="h4"
        fontWeight={300}
        color={"#494E54"}
        fontSize={"12px"}
        my={2}
        gutterBottom
      >
        Practices
      </Typography>
      <Stack direction={{ lg: "row" }} alignItems={{ lg: "center" }} gap={2}>
        <Tag label="Practice Name" status="display" />
        <Tag label="Practice Name Two" status="display" />
      </Stack>
      <Typography
        variant="h4"
        fontWeight={300}
        color={"#494E54"}
        fontSize={"12px"}
        my={2}
        gutterBottom
      >
        People
      </Typography>
      <Stack direction={{ lg: "row" }} alignItems={{ lg: "center" }} gap={2}>
        <Tag label="Practice Name" status="display" />
        <Tag label="Practice Name Two" status="display" />
      </Stack>
      <Stack
        direction={{ lg: "row" }}
        alignItems={{ lg: "center" }}
        gap={2}
        my={3}
      >
        <Stack direction={{ lg: "column" }}>
          <Typography
            variant="h4"
            fontWeight={300}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            Country
          </Typography>
          <Typography
            variant="h4"
            fontWeight={700}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            {data?.country?.name || ""}
          </Typography>
        </Stack>
        <Stack direction={{ lg: "column" }}>
          <Typography
            variant="h4"
            fontWeight={300}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            Date Voted
          </Typography>
          <Typography
            variant="h4"
            fontWeight={700}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            27 May 2024
          </Typography>
        </Stack>
        <Stack direction={{ lg: "column" }}>
          <Typography
            variant="h4"
            fontWeight={300}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            Geographical Zone
          </Typography>
          <Typography
            variant="h4"
            fontWeight={700}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            {data?.geographicalArea?.name || data?.practiceType?.name || ""}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction={{ lg: "row" }}
        alignItems={{ lg: "center" }}
        gap={2}
        my={3}
      >
        <Stack direction={{ lg: "column" }}>
          <Typography
            variant="h4"
            fontWeight={300}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            Theme
          </Typography>
          <Typography
            variant="h4"
            fontWeight={700}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            {data?.theme?.title || ""}
          </Typography>
        </Stack>
        <Stack direction={{ lg: "column" }}>
          <Typography
            variant="h4"
            fontWeight={300}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            Category
          </Typography>
          <Typography
            variant="h4"
            fontWeight={700}
            color={"#494E54"}
            fontSize={"12px"}
            gutterBottom
          >
            {data?.policyCategory?.name || data?.practiceCategory?.name || ""}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction={{ lg: "column" }}>
        <Typography
          variant="h4"
          fontWeight={300}
          color={"#494E54"}
          fontSize={"12px"}
          gutterBottom
        >
          External Policy Link
        </Typography>
        <Typography
          variant="h4"
          fontWeight={700}
          color={"#00AAB0"}
          fontSize={"14px"}
          gutterBottom
        >
          {data?.link || ""}
        </Typography>
      </Stack>
    </>
  );
}

export { RightSide };
