import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { AppBar,PageTitle } from "../../components";

const Support: React.FC = () => {
  return (
    <Box minHeight={610}>
      <AppBar>
        <PageTitle title="Support" />
      </AppBar>
      <Container>
        <Typography variant="overline">
          This page is under development
        </Typography>
      </Container>
    </Box>
  );
};

export { Support };
