import { gql } from "@apollo/client";
import { Header } from "../../../components";
import { AdminView } from "../../../features";
import { useApolloQuery } from "../../../hooks";

const pendingPracticeDocument = gql`
  query ($limit: Int, $page: Int) {
    pendingPractices(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        id
        link
        picture
        policy {
          title
        }
        practiceCategory {
          name
        }
        practiceType {
          name
        }
        status
        theme {
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const noPendingDocument = gql`
  query ($limit: Int, $page: Int) {
    noPendingPractices(limit: $limit, page: $page) {
      collection {
        country {
          name
        }
        createdAt
        date
        description
        document
        id
        link
        picture
        policy {
          title
        }
        practiceCategory {
          name
        }
        practiceType {
          name
        }
        status
        theme {
          title
        }
        title
        updatedAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

function AdminPractices() {
  const { data: pending } = useApolloQuery({
    queryDocument: pendingPracticeDocument,
    isPrivateCall: true,
    variables: {
      limit: 8,
    },
  });

  const {
    data: noPending,
    loading,
    refetch,
  } = useApolloQuery({
    queryDocument: noPendingDocument,
    isPrivateCall: true,
    variables: {
      limit: 8,
    },
  });

  return (
    <>
      <Header
        title="Admin Area"
        subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX"
      />
      <AdminView
        pendingData={pending?.pendingPractices?.collection || []}
        noPendingData={noPending?.noPendingPractices?.collection || []}
        title="Practices"
        viewPath="/admin/practices/"
        viewAllSubmissionsPath="/admin/practices/view_all"
      />
    </>
  );
}

export { AdminPractices };
