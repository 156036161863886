import React from "react";
import { Box, Container, Stack, Paper, Typography } from "@mui/material";
import MuiTabs, { tabsClasses } from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import MuiAvatar from "@mui/material/Avatar";
import bgGradient from "../../@lib/images/bg-gradient-2.png";
import PersonalDetailsForm from "./PersonalDetailsForm";
import PasswordUpdateForm from "./PasswordUpdateForm";

import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useAuthSession } from "../../components/AuthSessionProvider";

const Banner = styled(Box)({
  height: 240,
  backgroundImage: `url(${bgGradient})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
});

const Avatar = styled(MuiAvatar)({
  border: `4px solid #ffffff`,
});

const Tabs = styled(MuiTabs)({
  borderRadius: 0,
  border: 0,
  borderBottom: `1px solid #E4E7EC`,
  [`.${tabsClasses.indicator}`]: {
    display: "block",
  },
});

const Tab = styled(MuiTab)({
  borderRight: "none",
});

const AccountSettings: React.FC = () => {
  const [currTabIndex, setCurrTabIndex] = useState(0);
  const { user } = useAuthSession() as any;

  return (
    <Box mb={24}>
      <Banner />
      <Paper component={Container} sx={{ pb: 3, mb: 3 }}>
        <Stack direction="row" gap={3} alignItems="center">
          <Avatar sx={{ width: 180, height: 180, mt: -5 }} />
          <div>
            <Typography variant="h3">{`${user?.firstName} ${user?.lastName}`}</Typography>
            <Typography>{user?.email}</Typography>
          </div>
        </Stack>
      </Paper>

      <Container>
        <Paper component={Container} sx={{ pb: 6 }}>
          <Tabs value={currTabIndex} onChange={(ev, i) => setCurrTabIndex(i)}>
            <Tab label="My details" />
            <Tab label="Password" />
          </Tabs>

          {currTabIndex === 0 && (
            <>
              <Box py={6}>
                <PersonalDetailsForm user={user} />
              </Box>
            </>
          )}

          {currTabIndex === 1 && (
            <>
              <Box pb={6}>
                <PasswordUpdateForm user={user} />
              </Box>
            </>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export { AccountSettings };
