import { useState } from "react";
import { Box, Container, Fade, Grid, Slide } from "@mui/material";
import {
  Card,
  CardSkeleton,
  Filters,
  Header,
  LatestReport,
  TabsFilters,
  useLanguageTranslator,
} from "../../../components";
import {
  PolicyStatus,
  usePoliciesDocumentsCall,
  usePublicPoliciesCall,
} from "../../../hooks";

function PublicPolicies() {
  const [filters, setFilters] = useState({
    country: "",
    startYear: "",
    endYear: "",
    theme: "",
    geographicalArea: "",
  });
  const [currTab, setCurrTab] = useState<PolicyStatus | "all">("all");

  const { policyDocuments } = usePoliciesDocumentsCall({
    variables: { limit: 8 },
  });

  const { translator } = useLanguageTranslator();

  const {
    policies = [],
    loading,
    refetch,
    tabs,
  } = usePublicPoliciesCall({
    variables: {
      limit: 8,
      ...(filters?.country ? { countryId: Number(filters?.country) } : {}),
      ...(filters?.theme ? { themeId: Number(filters?.theme) } : {}),
      ...(filters?.startYear ? { startYear: Number(filters?.startYear) } : {}),
      ...(filters?.endYear ? { endYear: Number(filters?.endYear) } : {}),
      ...(filters?.geographicalArea
        ? { geographicalAreaId: Number(filters?.geographicalArea) }
        : {}),
    },
    currTab: currTab,
  });

  return (
    <Box pb={4}>
      <Header
        title={translator("Policies")}
        subtitle="Lorem ipsum dolor sit amet."
      />
      <Filters
        setFilters={setFilters}
        filters={filters}
        isPublicPolicies={true}
      />
      <Container sx={{ my: 3 }}>
        <TabsFilters
          currTab={currTab}
          setCurrTab={setCurrTab}
          listItems={tabs}
          heading={`${translator("List of")} ${translator("Policies")}`}
        />
        <Grid container spacing={2}>
          {policies?.map((p: any, i: any) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card {...p} path={`${p?.id}`} sx={{ height: "100%" }} />
              </Slide>
            </Grid>
          ))}
          {loading &&
            policies.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {CardSkeleton}
                  </Grid>
                </Fade>
              ))}
        </Grid>
      </Container>
      <LatestReport items={policyDocuments} path="/policies/viewAllReports" />
    </Box>
  );
}

export { PublicPolicies };
