import { FC, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import { gql, useMutation } from "@apollo/client";

// mutation {
//   updatePassword(
//     input: {
//       currentPassword: "string"
//       newPassword: "string"
//       newPasswordConfirmation: "string"
//     }
//   ) {
//     clientMutationId
//     user {
//       companyName
//       email
//       firstName
//       id
//       isConfirmed
//       isLocked
//       lang
//       lastName
//       profile{name}
//     }
//   }
// }

const updatePasswordMutationQuery = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      clientMutationId
      user {
        companyName
        email
        firstName
        id
        isConfirmed
        isLocked
        lang
        lastName
        profile {
          name
        }
      }
    }
  }
`;

const PasswordUpdateForm = ({ user }: any) => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [isValid, setIsValid] = useState(true);

  const [updatePassword, { loading }] = useMutation(
    updatePasswordMutationQuery,
    {
      context: {
        headers: {
          "access-token": user?.accessToken,
          client: user?.client,
          uid: user?.email,
        },
      },
    }
  );

  function handleChange(e: any) {
    const { name, value } = e?.target;
    if (name === "newPassword") {
      setFormData({ ...formData, [name]: value });
      setIsValid(value.length > 8);
      return;
    }

    setFormData({ ...formData, [name]: value });
  }

  async function handleSubmit() {
    if (formData?.newPassword === formData?.confirmNewPassword) {
      const response = await updatePassword({
        variables: {
          input: {
            currentPassword: formData?.currentPassword,
            newPassword: formData?.newPassword,
            newPasswordConfirmation: formData?.confirmNewPassword,
          },
        },
      });
    } else {
      alert("password Not same ");
    }
  }

  const isFormFieldsFill =
    !formData?.currentPassword ||
    !formData?.newPassword ||
    !formData?.confirmNewPassword;

  return (
    <>
      <form>
        <Box py={4}>
          <Typography fontSize={20} fontWeight={500}>
            Password
          </Typography>
          <Typography fontWeight={300}>
            Please enter your current password to change your password.
          </Typography>
        </Box>

        <Stack gap={3}>
          <Divider />

          <Stack direction={{ md: "row" }} gap={1}>
            <Typography sx={{ flexBasis: { md: "40%", lg: "30%", xl: "25%" } }}>
              Current password
            </Typography>
            <FormControl sx={{ flexBasis: { md: "60%", lg: "40%" } }}>
              <OutlinedInput
                name="currentPassword"
                type="password"
                onChange={handleChange}
              />
            </FormControl>
          </Stack>

          <Divider />

          <Stack direction={{ md: "row" }} gap={1}>
            <Typography sx={{ flexBasis: { md: "40%", lg: "30%", xl: "25%" } }}>
              New password
            </Typography>
            <FormControl sx={{ flexBasis: { md: "60%", lg: "40%" } }}>
              <OutlinedInput
                name="newPassword"
                type="password"
                onChange={handleChange}
              />
              <FormHelperText sx={{ mt: 1, color: isValid ? "black" : "red" }}>
                Your new password must be more than 8 characters.
              </FormHelperText>
            </FormControl>
          </Stack>

          <Divider />

          <Stack direction={{ md: "row" }} gap={1}>
            <Typography sx={{ flexBasis: { md: "40%", lg: "30%", xl: "25%" } }}>
              Confirm new password
            </Typography>
            <FormControl sx={{ flexBasis: { md: "60%", lg: "40%" } }}>
              <OutlinedInput
                name="confirmNewPassword"
                type="password"
                onChange={handleChange}
              />
            </FormControl>
          </Stack>
        </Stack>
      </form>
      <Divider sx={{ my: 2 }} />
      <Stack direction="row" justifyContent="flex-end" gap={2}>
        <Button size="large" variant="contained" color="inherit" sx={{ px: 2 }}>
          Cancel
        </Button>
        <Button
          size="large"
          variant="contained"
          sx={{
            px: 2,
            opacity: isFormFieldsFill ? 0.7 : 1,
            cursor: isFormFieldsFill ? "default" : "pointer",
          }}
          onClick={isFormFieldsFill ? () => {} : handleSubmit}
        >
          Update password
        </Button>
      </Stack>
    </>
  );
};

export default PasswordUpdateForm;
