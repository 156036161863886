import { LinearProgress, Stack } from "@mui/material";

import { AppBar } from "../appBar";
import { PageTitle } from "../pageTitle";
import { SearchBar } from "../searchBar";

function Header({ loading = false, title, subtitle }: any) {
  return (
    <AppBar>
      <Stack
        direction="row"
        flexGrow={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <PageTitle title={title} subtitle={subtitle} />

        <SearchBar placeholder="Search tree and keyword" />
      </Stack>
      {loading && (
        <LinearProgress
          sx={{ position: "absolute", left: 0, right: 0, bottom: 0 }}
        />
      )}
    </AppBar>
  );
}

export { Header };
