import type { FC, ReactNode } from "react";
import type { TabsProps } from "@mui/material/Tabs";
import ButtonTabs, { ButtonTab } from "../../components/@common/ButtonTabs";
import { MdError } from "react-icons/md";

import { useState } from "react";

export type CareerDetailTabsProps = TabsProps & {
  errors?: {
    action?: boolean;
    position?: boolean;
  };
  render: (currTab: number) => ReactNode;
};

const CareerDetailTabs: FC<CareerDetailTabsProps> = (props) => {
  const { errors, render, children, ...tabsProps } = props;

  const [currTabIndex, setCurrTabIndex] = useState(0);

  return (
    <>
      <ButtonTabs
        {...tabsProps}
        value={currTabIndex}
        onChange={(_, i) => setCurrTabIndex(i)}
      >
        <ButtonTab
          label="Action"
          icon={errors?.action ? <MdError color="red" size={24} /> : undefined}
          iconPosition="end"
        />
        <ButtonTab
          label="Position"
          icon={
            errors?.position ? <MdError color="red" size={24} /> : undefined
          }
          iconPosition="end"
        />
      </ButtonTabs>

      {props.render(currTabIndex)}
    </>
  );
};

export default CareerDetailTabs;
