import { Container, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import {
  usePracticesDocumentsCall,
  usePublicPracticesCall,
} from "../../../hooks";
import {
  Card,
  LatestReport,
  useLanguageTranslator,
} from "../../../components";

function Practices() {
  const { practiceDocuments } = usePracticesDocumentsCall({
    variables: { limit: 5 },
  });
  const { practices } = usePublicPracticesCall({ variables: { limit: 4 } });
  const { translator } = useLanguageTranslator();

  return (
    <>
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">{translator("Practices")}</Typography>
            <Link href="/practices/all" underline="none" color="textSecondary">
              {translator("View all")}
            </Link>
          </Stack>
        </Paper>

        <Grid container spacing={2}>
          {practices?.map((elem: any, i: number) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Card
                status={elem?.status}
                path={`/practices/${elem?.id}`}
                title={elem?.title}
                description={elem?.description as any}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      <LatestReport
        items={practiceDocuments}
        path="/practices/viewAllReports"
      />
    </>
  );
}

export { Practices };
