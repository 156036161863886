import React from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { RouterProvider } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import AuthSessionProvider from "./components/AuthSessionProvider";
import NotificationsProvider from "./components/@common/NotificationsProvider";

import lightTheme from "./@lib/themes/theme-light";
import router from "./router";
import graphqlClient from "./@lib/services/api/graphql-client";
import "./i18next";
import { LanguageProvider } from "./components/useLanguageContext";

function App() {
  return (
    <React.Suspense fallback="loading">
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <ApolloProvider client={graphqlClient}>
          <AuthSessionProvider>
            <LanguageProvider>
              <NotificationsProvider>
                <RouterProvider router={router} />
              </NotificationsProvider>
            </LanguageProvider>
          </AuthSessionProvider>
        </ApolloProvider>
      </ThemeProvider>
    </React.Suspense>
  );
}

export default App;
