
import React from 'react';
import Typography from '@mui/material/Typography';

export type PageTitleProps = {
  title: string;
  subtitle?: string;
};

const PageTitle: React.FC<PageTitleProps> = (props) => {
  return (
    <div>
      <Typography variant="subtitle1" lineHeight={1} noWrap>{props.title}</Typography>
      {props.subtitle && <Typography variant="caption" noWrap>{props.subtitle}</Typography>}
    </div>
  );
};

export { PageTitle };
