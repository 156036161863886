import React from "react";
import { Navigate, useLocation, createBrowserRouter } from "react-router-dom";

import {
  DrawerLayout,
  MainSideNav,
  FooterPublic,
  AuthLayout,
} from "./components";
import Login from "./auth/Login";
import {
  Dashboard,
  Indicators,
  Support,
  AccountSettings,
  PeopleHome,
  PeopleList,
  PersonDetail,
  PersonNew,
  AdminPeople,
  PublicPolicies,
  PrivatePolicies,
  PoliciesList,
  PolicyDetails,
  PolicyEdit,
  PolicyNew,
  PrivatePoliciesList,
  AdminPolicies,
  PracticeDetails,
  PracticeEdit,
  PracticeNew,
  PublicPractices,
  PrivatePractices,
  PracticesList,
  PrivatePracticesList,
  AdminPractices,
} from "./pages";

import useAuthSession from "./components/AuthSessionProvider/useAuthSession";
import { userRoleType } from "./utils/constants";
import {
  AdminDetailsView,
  AdminSubmissionALL,
  AdminViewAll,
  AllReports,
} from "./features";

type AuthGuardProps = {
  policy:
    | "require_auth"
    | "require_no_auth"
    | "require_admin_auth"
    | "require_both_auth";
  redirectTo: string;
  children?: React.ReactNode;
};

const AuthGuard: React.FC<AuthGuardProps> = (props) => {
  const { user } = useAuthSession() as any;
  const isAuthenticated = !!user;
  switch (props.policy) {
    case "require_both_auth": {
      if (isAuthenticated) return <>{props.children}</>;
      else return <Navigate to={props.redirectTo} />;
    }
    case "require_auth": {
      if (user?.profile?.name === userRoleType?.student)
        return <>{props.children}</>;
      else return <Navigate to={props.redirectTo} />;
    }
    case "require_admin_auth": {
      if (user?.profile?.name === userRoleType?.admin)
        return <>{props.children}</>;
      else return <Navigate to={props.redirectTo} />;
    }
    case "require_no_auth": {
      if (!isAuthenticated) return <>{props.children}</>;
      else return <Navigate to={props.redirectTo} />;
    }
    default: {
      return <>{props.children}</>;
    }
  }
};

function RefreshableIndicators() {
  const location = useLocation();

  return <Indicators key={`${location.pathname}${location.search}`} />;
}

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <DrawerLayout
        drawerContent={<MainSideNav />}
        footerContent={<FooterPublic />}
      />
    ),
    children: [
      { index: true, element: <Dashboard /> },
      { path: "indicators", element: <RefreshableIndicators /> },
      { path: "policies", element: <PublicPolicies /> },
      { path: "policies/all", element: <PoliciesList /> },
      { path: ":module/viewAllReports", element: <AllReports /> },
      { path: "policies/:policyId", element: <PolicyDetails /> },

      { path: "practices", element: <PublicPractices /> },
      { path: "practices/all", element: <PracticesList /> },
      { path: "practices/:practiceId", element: <PracticeDetails /> },

      { path: "people", element: <PeopleHome /> },
      { path: "personDetail", element: <PersonDetail /> },
      { path: "people/all", element: <PeopleList /> },

      { path: "support", element: <Support /> },

      // protected nested route
      {
        path: "admin/:basePath/all",
        element: (
          <AuthGuard policy="require_admin_auth" redirectTo="/auth/login">
            <AdminViewAll />
          </AuthGuard>
        ),
      },
      {
        path: "admin/:basePath/view_all",
        element: (
          <AuthGuard policy="require_admin_auth" redirectTo="/auth/login">
            <AdminSubmissionALL />
          </AuthGuard>
        ),
      },
      {
        path: "admin/:basePath/:Id",
        element: (
          <AuthGuard policy="require_admin_auth" redirectTo="/auth/login">
            <AdminDetailsView />
          </AuthGuard>
        ),
      },
      {
        path: "admin/person_detail",
        element: (
          <AuthGuard policy="require_admin_auth" redirectTo="/auth/login">
            <PersonDetail />
          </AuthGuard>
        ),
      },
      {
        path: "admin/people",
        element: (
          <AuthGuard policy="require_admin_auth" redirectTo="/auth/login">
            <AdminPeople />
          </AuthGuard>
        ),
      },
      {
        path: "admin/practices",
        element: (
          <AuthGuard policy="require_admin_auth" redirectTo="/auth/login">
            <AdminPractices />
          </AuthGuard>
        ),
      },
      {
        path: "admin/policies",
        element: (
          <AuthGuard policy="require_admin_auth" redirectTo="/auth/login">
            <AdminPolicies />
          </AuthGuard>
        ),
      },
      {
        path: "submission/dashboard",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <Dashboard />
          </AuthGuard>
        ),
      },
      {
        path: "practices/:practiceId/edit",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PracticeEdit />
          </AuthGuard>
        ),
      },

      {
        path: "account-settings",
        element: (
          <AuthGuard policy="require_both_auth" redirectTo="/auth/login">
            <AccountSettings />
          </AuthGuard>
        ),
      },
      {
        path: "submission/people",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PeopleHome />
          </AuthGuard>
        ),
      },
      {
        path: ":basePath/people/:module",
        element: (
          <AuthGuard policy="require_both_auth" redirectTo="/auth/login">
            <PersonNew />
          </AuthGuard>
        ),
      },
      {
        path: "admin/people/new",
        element: (
          <AuthGuard policy="require_both_auth" redirectTo="/auth/login">
            <PersonNew />
          </AuthGuard>
        ),
      },
      {
        path: "submission/people/all",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PeopleList />
          </AuthGuard>
        ),
      },

      {
        path: "submission/policies",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PrivatePolicies />
          </AuthGuard>
        ),
      },
      {
        path: "submission/policies/all",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PrivatePoliciesList />
          </AuthGuard>
        ),
      },
      {
        path: ":basePath/policies/new",
        element: (
          <AuthGuard policy="require_both_auth" redirectTo="/auth/login">
            <PolicyNew />
          </AuthGuard>
        ),
      },
      {
        path: "policies/:policyId/edit",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PolicyEdit />
          </AuthGuard>
        ),
      },

      {
        path: "submission/practices",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PrivatePractices />
          </AuthGuard>
        ),
      },
      {
        path: ":basePath/practices/new",
        element: (
          <AuthGuard policy="require_both_auth" redirectTo="/auth/login">
            <PracticeNew />
          </AuthGuard>
        ),
      },
      {
        path: "submission/practices/all",
        element: (
          <AuthGuard policy="require_auth" redirectTo="/auth/login">
            <PrivatePracticesList />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: "auth",
    element: (
      <AuthGuard policy="require_no_auth" redirectTo="/">
        <AuthLayout />
      </AuthGuard>
    ),
    children: [
      { index: true, element: <Navigate to="login" /> },
      { path: "login", element: <Login /> },
    ],
  },
]);

export default router;
