import { useState } from "react";
import { Container, Stack, Paper, Button } from "@mui/material";
import {
  CardAll,
  ConditionalRenderer,
  Filters,
  Header,
  TabsFilters,
} from "../../../components";
import { usePublicPracticesCall } from "../../../hooks";

function PracticesList() {
  const [filters, setFilters] = useState({
    country: "",
    startYear: null,
    endYear: null,
    theme: "",
  });
  const [currTabIndex, setCurrTabIndex] = useState("all");
  const [limit, setLimit] = useState(8);
  const { practices, tabs, loading, metadata }: any = usePublicPracticesCall({
    variables: {
      limit: limit,
      ...(filters?.country ? { countryId: Number(filters?.country) } : {}),
      ...(filters?.theme ? { themeId: Number(filters?.theme) } : {}),
      ...(filters?.startYear ? { startYear: Number(filters?.startYear) } : {}),
      ...(filters?.endYear ? { endYear: Number(filters?.endYear) } : {}),
    },
    currTabIndex: currTabIndex,
  });

  function onClickHandler() {
    setLimit(limit + 8);
  }

  const hasMore =
    limit === metadata?.totalCount ||
    limit > metadata?.totalCount ||
    !metadata?.totalCount;

  return (
    <div>
      <Header
        title="Practices"
        subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
      />
      <Filters setFilters={setFilters} filters={filters} />

      <Container sx={{ my: 3 }}>
        <TabsFilters
          currTab={currTabIndex}
          setCurrTab={setCurrTabIndex}
          listItems={tabs}
          heading="List of practices data"
          isAllShow={true}
        />

        <Stack gap={2}>
          {practices?.map((p: any, i: any) => (
            <CardAll
              key={`practice-list-card-${i}`}
              p={p}
              loading={loading}
              path={`/practices/${p.id}`}
            />
          ))}
        </Stack>
      </Container>

      <ConditionalRenderer condition={!hasMore}>
        <Container sx={{ my: 3 }}>
          <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button onClick={onClickHandler}>Show more</Button>
          </Paper>
        </Container>
      </ConditionalRenderer>
    </div>
  );
}

export { PracticesList };
