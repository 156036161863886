import { gql, useQuery } from "@apollo/client";
import { Practice } from "../../../@lib/types";

type PracticesCollectionData = {
  practices: Practice[];
};

const fetchPracticesDocuments = gql`
  query ($limit: Int, $page: Int) {
    practiceDocuments(limit: $limit, page: $page) {
      collection {
        createdAt
        filename
        id
        path
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const fetchPoliciesDocuments = gql`
  query ($limit: Int, $page: Int) {
    policyDocuments(limit: $limit, page: $page) {
      collection {
        createdAt
        filename
        id
        path
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

function useDataCall({ module, variables }: any) {
  const queryCall =
    module === "policies" ? fetchPoliciesDocuments : fetchPracticesDocuments;
  const data = useQuery<PracticesCollectionData>(queryCall, {
    fetchPolicy: "cache-and-network",
    variables: variables,
  });

  return data || {};
}

export { useDataCall };
