import { Paper } from "@mui/material";
import TableHead from "../../../components/@common/TableHead";
import TableBody from "../../../components/@common/TableBody";
import {
  Box,
  Container,
  TableContainer,
  Table,
  TableRow,
  Link,
} from "@mui/material";
import MuiTableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import {
  ConditionalRenderer,
  useLanguageTranslator,
} from "../../../components";
import StatusTag from "../../../components/@common/StatusTag";

const TableCell = styled(MuiTableCell)({
  textWrap: "nowrap",
});

function SubmissionTable({
  viewAllPath = "",
  isAllShow = false,
  data = [],
  viewPath = "",
}: any) {
  const { translator } = useLanguageTranslator();
  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{translator("dashboardTableHead.Name")}</TableCell>
              <TableCell>
                {translator("dashboardTableHead.Description")}
              </TableCell>
              <TableCell>{translator("dashboardTableHead.Status")}</TableCell>
              <TableCell>
                {translator("dashboardTableHead.Uploaded date")}
              </TableCell>
              <TableCell>{translator("dashboardTableHead.Action")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((elem: any, i: number) => (
              <TableRow key={`${i}`}>
                <TableCell>
                  {elem?.title} {i + 1}
                </TableCell>
                <TableCell>{elem?.description}</TableCell>
                <TableCell padding="checkbox">
                  <StatusTag
                    label={
                      elem?.status?.charAt(0)?.toUpperCase() +
                      elem?.status?.slice(1)
                    }
                    status={elem?.status}
                  />
                </TableCell>
                <TableCell align="right">18.05.2024</TableCell>
                <TableCell padding="checkbox">
                  <Link
                    href={`${viewPath}${elem?.id}`}
                    sx={{
                      backgroundColor: "#00AAB0",
                      color: "white",
                      padding: "6px 30px",
                      borderRadius: "25px",
                    }}
                  >
                    View
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ConditionalRenderer condition={!isAllShow}>
        <Box component={Container} sx={{ py: 3 }} textAlign="right">
          <Link href={viewAllPath} underline="none" color="textSecondary">
            {translator("View all")}
          </Link>
        </Box>
      </ConditionalRenderer>
    </Paper>
  );
}

export { SubmissionTable };
