import { gql } from "@apollo/client";
import { useApolloQuery } from "../useApolloQuery";

const fetchLatestPositions = gql`
  query ($limit: Int, $page: Int, $countryId: Int, $themeId: Int) {
    peoplePositions(
      page: $page
      limit: $limit
      countryId: $countryId
      themeId: $themeId
    ) {
      collection {
        description
        firstname
        lastname
        positionDate
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
  }
`;

const useLatestPositions = ({ variables }: any) => {
  const { data, loading } = useApolloQuery({
    queryDocument: fetchLatestPositions,
    variables: variables,
  });

  return {
    data: data?.peoplePositions,
    loading,
  };
};

export { useLatestPositions };
