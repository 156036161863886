import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  Slide,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SubmissionTable } from "./table";
import { Modal, Card, useLanguageTranslator } from "../../components";
import { useState } from "react";

function AdminView({
  pendingData = [],
  noPendingData = [],
  title = "",
  viewPath,
  viewAllSubmissionsPath,
}: any) {
  const [isOpen, setIsOpen] = useState<any>({
    status: false,
    currId: null,
    module: "rejected",
  });

  const { translator } = useLanguageTranslator();

  function onAdminActions(id: any, type: any) {
    setIsOpen({ status: true, currId: 0, module: type });
  }

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3 }}>
          <Stack
            direction={{ lg: "row" }}
            gap={2}
            justifyContent={{ lg: "space-between" }}
            alignItems={{ lg: "center" }}
          >
            <Box>
              <Typography variant="h4">
                {translator("Students Submissions")}
              </Typography>
            </Box>
            <Box>
              <Button
                component={RouterLink}
                to="new"
                sx={{
                  px: 6,
                  backgroundColor: "#F6A000",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "primary.light",
                  },
                }}
              >
                {translator("generic.CREATE")}
              </Button>
            </Box>
          </Stack>
        </Paper>
        <SubmissionTable
          viewAllPath={viewAllSubmissionsPath}
          data={pendingData}
          viewPath={viewPath}
        />
      </Container>
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 2, mb: 2 }}>
          <Stack
            direction={{ lg: "row" }}
            justifyContent={{ lg: "space-between" }}
            alignItems={{ lg: "center" }}
          >
            <Typography variant="h4">Approved & Rejected {title}</Typography>
            <Link
              component={RouterLink}
              to="all"
              underline="none"
              color="textSecondary"
            >
              View all
            </Link>
          </Stack>
        </Paper>
        <Grid container spacing={2}>
          {noPendingData?.map((p: any, i: any) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Slide in timeout={180 * (i + 1)} direction="right">
                <Card
                  {...p}
                  path={`${viewPath}${p?.id}`}
                  sx={{ height: "100%" }}
                  isAdmin={true}
                  onAdminActions={onAdminActions}
                />
              </Slide>
            </Grid>
          ))}

          {/* {loading &&
            policies.length === 0 &&
            Array(4)
              .fill(null)
              .map((_, i) => (
                <Fade key={`${i}`} in timeout={180 * (i + 1)}>
                  <Grid item xs={12} md={6} lg={3}>
                    {CardSkeleton}
                  </Grid>
                </Fade>
              ))} */}
        </Grid>
      </Container>
    </>
  );
}

export { AdminView };
