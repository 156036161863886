import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";
import { useLanguageTranslator } from "../../useLanguageContext";
import { DownArrowIcon, MySpaceIcon } from "../../../@lib/icons";
import { ConditionalRenderer } from "../../conditionalRenderer";

function HeadDropdown({ children }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { translator } = useLanguageTranslator();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ListItemIcon sx={{ minWidth: 37 }}>
              <MySpaceIcon />
            </ListItemIcon>
            <ListItemText
              primary={translator("Admin Area")}
              primaryTypographyProps={{
                fontWeight: 500,
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              right: 13,
              top: 0,
              display: "flex",
              alignItems: "center",
              height: "100%",
              transition: "transform 0.3s ease",
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
          >
            <DownArrowIcon />
          </div>
        </div>
      </ListItemButton>
      <ConditionalRenderer condition={open}>{children}</ConditionalRenderer>
    </>
  );
}

export { HeadDropdown };
