import React from "react";
import type { Person } from "../../@lib/types";
import Card from "@mui/material/Card";
import CoverImage from "../@common/CoverImage";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import placeholderImage from "../../@lib/images/person-placeholder.jpg";

import { styled } from "@mui/material/styles";
import { ConditionalRenderer } from "../conditionalRenderer";
import { useAuthSession } from "../AuthSessionProvider";
import { Button, Stack } from "@mui/material";

export type PersonCardProps = {
  person?: Pick<
    Person,
    "firstname" | "lastname" | "picture" | "id" | "title" | any
  >;
  children?: React.ReactNode;
  isPrivate?: boolean;
  editPath?: string;
};

const CardActionButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  borderRadius: 100,
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  fontSize: 12,
  flex: 1,
  color: "#ffffff",
}));

const CardMedia = styled(CoverImage)({
  position: "relative",
  "&:before": {
    content: `""`,
    display: "block",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: "linear-gradient(to top, #ffffff, transparent 40%)",
  },
});

const DetailsWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  backgroundColor: "#494e5499",
  color: "#ffffff",
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  position: "absolute",
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  right: theme.spacing(2),
}));

const PersonCard: React.FC<PersonCardProps> = (props) => {
  const { person, children, isPrivate = false, editPath = "" } = props;

  const { user } = useAuthSession();
  const isLoggedIn = !!user;

  return (
    <>
      <Card
        sx={{ position: "relative" }}
        component={RouterLink}
        to={`/personDetail?id=${person?.id}`}
      >
        <CardMedia height={414} image={person?.picture || placeholderImage} />
        <CardContent>
          <DetailsWrapper>
            <Typography
              variant="h5"
              mb={1}
              sx={{ textTransform: "capitalize" }}
            >
              {person?.firstname} {person?.lastname}
            </Typography>
            <Typography variant="subtitle2">Country</Typography>
            <Typography component="p" variant="caption">
              {person?.title}
            </Typography>
          </DetailsWrapper>
        </CardContent>
        {children}
      </Card>
      <ConditionalRenderer
        condition={isPrivate && isLoggedIn && person?.status !== "approved"}
      >
        <Stack direction="row" spacing={1}>
          <CardActionButton
            size="small"
            variant="contained"
            color="warning"
            // disabled={disabled}
            href={editPath}
          >
            Edit
          </CardActionButton>
          <CardActionButton
            size="small"
            variant="contained"
            color="error"
            // disabled={disabled}
            // onClick={() => onDelete && onDelete(id)}
          >
            DELETE
          </CardActionButton>
        </Stack>
      </ConditionalRenderer>
    </>
  );
};

export default PersonCard;
