import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Link,
  Typography,
} from "@mui/material";
import { Header } from "../../components";
import { LeftArrowIcon } from "../../@lib/icons";
import { RightSide } from "./rightSide";
import { LeftSide } from "./leftSide";
import { useLocation } from "react-router-dom";
import { gql } from "@apollo/client";
import { useApolloQuery } from "../../hooks";

const policyQueryDocument = gql`
  query policyQuery($policyId: ID!) {
    policy(id: $policyId) {
      title
      description
      country {
        name
      }
      policyCategory {
        name
      }
      theme {
        title
      }
      geographicalArea {
        name
      }
      picture
      document
      link
      date
      status
    }
  }
`;

const practiceQueryDocument = gql`
  query practiceQuery($practiceId: ID!) {
    practice(id: $practiceId) {
      title
      description
      country {
        name
      }
      practiceCategory {
        name
      }
      theme {
        title
      }
      practiceType {
        name
      }
      policy {
        title
      }
      picture
      document
      link
      date
      status
    }
  }
`;

function isModule(location: any) {
  const isPractices = location?.pathname?.includes("practices") || false;
  const isPolicies = location?.pathname?.includes("policies") || false;

  const path = location?.pathname?.split("/");

  if (isPolicies) {
    return {
      queryDocument: policyQueryDocument,
      createPath: "/admin/policies/new",
      heading: "Policies",
      params: { policyId: path?.[3] },
    };
  } else if (isPractices) {
    return {
      queryDocument: practiceQueryDocument,
      createPath: "/admin/practices/new",
      heading: "Practices",
      params: { practiceId: path?.[3] },
    };
  } else {
    return {
      queryDocument: gql``,
      createPath: "",
      heading: "",
      params: {},
    };
  }
}

function AdminDetailsView() {
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const { queryDocument, createPath, heading, params }: any =
    isModule(location);

  const { data, loading } = useApolloQuery({
    queryDocument: queryDocument,
    variables: params,
  });

  return (
    <>
      <Header
        title="Admin Area"
        subtitle="NGO Detail will goes here, we can utilize double line text as well, No image required due to good UX"
      />
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3 }}>
          <Stack direction={{ lg: "row" }} gap={2}>
            <Box>
              <Button
                sx={{
                  alignItems: "center",
                  display: "flex",
                  fontSize: "14px",
                  color: "#979da7",
                  fontWeight: "700",
                }}
              >
                <LeftArrowIcon /> Go back
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: "80%",
              }}
            >
              <Typography variant="h4">Viewing {heading}</Typography>
            </Box>
          </Stack>
        </Paper>
      </Container>

      <Container sx={{ my: 3 }}>
        <Stack direction={{ lg: "row" }}>
          <Paper
            component={Container}
            sx={{ py: 3, flex: 3, borderRadius: "0px", boxShadow: "none" }}
          >
            <LeftSide data={data?.policy || data?.practice} />
          </Paper>
          <Paper
            component={Container}
            sx={{ py: 3, flex: 1, borderRadius: "0px", boxShadow: "none" }}
          >
            <RightSide data={data?.policy || data?.practice} />
          </Paper>
        </Stack>
        <Stack
          direction={{ lg: "row" }}
          justifyContent={{ lg: "end" }}
          gap={1}
          sx={{ backgroundColor: "white", pr: 2, pb: 1 }}
        >
          <Button
            sx={{
              px: 6,
              backgroundColor: "#52D777",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "primary.light",
              },
              fontSize: "14px",
            }}
          >
            Approve
          </Button>
          <Button
            sx={{
              px: 6,
              backgroundColor: "#FF6D6D",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "primary.light",
              },
              fontSize: "14px",
            }}
          >
            Reject
          </Button>
          <Link
            sx={{
              px: 6,
              backgroundColor: "#F6A000",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "primary.light",
              },
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
            href={`/${path?.[2]}/${path?.[3]}/edit`}
          >
            Edit
          </Link>
          <Button
            sx={{
              px: 6,
              backgroundColor: "#CD0A0A",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "primary.light",
              },
              fontSize: "14px",
            }}
          >
            Delete
          </Button>
        </Stack>
      </Container>
    </>
  );
}

export { AdminDetailsView };
