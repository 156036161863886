import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Stack } from "@mui/material";
import { AppBar, PageTitle, SearchBar } from "../../../components";
import { PersonEditor } from "../../../features";

import { useNotify } from "../../../components/@common/NotificationsProvider";
import { useMutation, gql } from "@apollo/client";
import type { PersonInputData } from "../../../@lib/types";

const personCreateMutationDocument = gql`
  mutation PersonCreate($personInput: PersonCreateInput!) {
    personCreate(input: $personInput) {
      person {
        id
      }
    }
  }
`;

function PersonNew() {
  const navigate = useNavigate();
  const notify = useNotify();
  const [createPerson, { loading }] = useMutation(personCreateMutationDocument);

  const handleSave = useCallback(
    async (data: PersonInputData) => {
      try {
        await createPerson({
          variables: {
            personInput: {
              personInput: data,
            },
          },
        });

        notify.success("Person created successfuly!");
        navigate("/people");
      } catch (err) {
        notify.error(err as Error);
      }
    },
    [createPerson, navigate, notify]
  );

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box mb={24}>
      <AppBar>
        <Stack
          direction="row"
          flexGrow={1}
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <PageTitle
            title="Add new people"
            subtitle="Lorem ipsum dolor sit amet."
          />

          <SearchBar placeholder="Search tree and keyword" />
        </Stack>
      </AppBar>
      <Container sx={{ my: 3 }}>
        <PersonEditor
          disabled={loading}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      </Container>
    </Box>
  );
}

export { PersonNew };
