import { useState } from "react";
import { Container, Stack, Paper, Button } from "@mui/material";

import {
  CardAll,
  ConditionalRenderer,
  Filters,
  Header,
  TabsFilters,
} from "../../../components";
import { usePublicPoliciesCall } from "../../../hooks";

function PoliciesList() {
  const [filters, setFilters] = useState({
    country: "",
    startYear: "",
    endYear: "",
    theme: "",
    geographicalArea: "",
  });
  const [currTab, setCurrTab] = useState<"all" | any>("all");
  const [limit, setLimit] = useState(8);
  const { policies, tabs, loading, metadata } = usePublicPoliciesCall({
    variables: {
      limit: limit,
      ...(filters?.country ? { countryId: Number(filters?.country) } : {}),
      ...(filters?.theme ? { themeId: Number(filters?.theme) } : {}),
      ...(filters?.startYear ? { startYear: Number(filters?.startYear) } : {}),
      ...(filters?.endYear ? { endYear: Number(filters?.endYear) } : {}),
      ...(filters?.geographicalArea
        ? { geographicalAreaId: Number(filters?.geographicalArea) }
        : {}),
    },
    currTab: currTab,
  });

  function onClickHandler() {
    setLimit(limit + 8);
  }

  const hasMore =
    limit === metadata?.totalCount ||
    limit > metadata?.totalCount ||
    !metadata?.totalCount;

  return (
    <div>
      <Header
        title="Policies"
        subtitle="Lorem ipsum dolor sit, amet consectetur adipisicing elit."
      />
      <Filters
        setFilters={setFilters}
        filters={filters}
        isPublicPolicies={true}
      />

      <Container sx={{ my: 3 }}>
        <TabsFilters
          currTab={currTab}
          setCurrTab={setCurrTab}
          listItems={tabs}
          heading="List of policies data"
          isAllShow={true}
        />

        <Stack gap={2}>
          {policies?.map((p: any, i: any) => (
            <CardAll p={p} loading={loading} path={`/policies/${p.id}`} />
          ))}
        </Stack>
      </Container>

      <ConditionalRenderer condition={!hasMore}>
        <Container sx={{ my: 3 }}>
          <Paper sx={{ py: 2, display: "flex", justifyContent: "center" }}>
            <Button onClick={onClickHandler}>Show more</Button>
          </Paper>
        </Container>
      </ConditionalRenderer>
    </div>
  );
}

export { PoliciesList };
