
import React from 'react';
import type { OptionType,Policy } from '../../@lib/types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import StatusTag from '../../components/@common/StatusTag';

import { useEffect, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLazyQuery, gql } from '@apollo/client';
import { useNotify } from '../../components/@common/NotificationsProvider';

const policyQueryDocument = gql`
query PolicyQuery ($policyId: ID!) {
  policy (id: $policyId) {
    status
    description
    policyCategory {
      name
    }
    theme {
      title
    }
    country {
      name
    }
    geographicalArea {
      name
    }
  }
}
`;

export type PoliciesInfoFormProps = {
  disabled?: boolean;
  policyOptions: OptionType[];
};

const PoliciesInfoForm: React.FC<PoliciesInfoFormProps> = (props) => {
  const {
    policyOptions,
    disabled
  } = props;

  const notify = useNotify();
  const { trigger, getValues } = useFormContext();
  const [getPolicy, { data, loading }] = useLazyQuery<{ policy: Pick<Policy, 'status' | 'description' | 'policyCategory' | 'theme' | 'country' | 'geographicalArea'> }>(policyQueryDocument);

  /**
   * loadPolicy()
   */
  const loadPolicy = useCallback(async () => {
    try {
      const policyId = getValues('policyId');

      if (policyId)
        await getPolicy({
          variables: {
            policyId
          }
        });
    }
    catch (err) {
      notify.error((err as Error));
    }
  }, [getPolicy, getValues, notify]);

  useEffect(() => {
    loadPolicy();
  }, [loadPolicy]);

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2} alignItems="flex-end">
        <Controller
          name="policyId"
          rules={{ required: 'Required!' }}
          render={({ field, fieldState: { error } }) => (
            <FormControl
              error={!!error}
              disabled={disabled || loading}
              sx={{ flexBasis: { xs: '100%', md: '60%', lg: '40%' } }}
            >
              <FormLabel>Policy</FormLabel>
              <Select
                {...field}
                onChange={async (e) => {
                  field.onChange(e);
                  if (await trigger(field.name))
                    loadPolicy();
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>Select policy</MenuItem>
                {policyOptions.map((op, i) => (
                  <MenuItem key={`${op.value}-${i}`} value={op.value}>{op.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Link component={RouterLink} to="/policies/new">Create new policy</Link>
      </Stack>

      {data && !loading && (
        <Stack direction="row" gap={1}>
          <StatusTag label={data.policy.status} status={data.policy.status} />
          <Chip label={data.policy.policyCategory.name} />
          <Chip label={data.policy.theme.title} />
          <Chip label={data.policy.country.name} />
          <Chip label={data.policy.geographicalArea.name} />
        </Stack>
      )}
      <Box component={Paper} variant="outlined" bgcolor="grey.100" sx={{ minHeight: 300, p: 2 }}>
        {loading && <CircularProgress />}
        {!data && !loading && <Typography variant="caption">No policies already selected</Typography>}
        {data && !loading && (
          <Typography>{data.policy.description}</Typography>
        )}
      </Box>
    </Stack>
  );
};

export default PoliciesInfoForm;
