import { Container, Grid, Link, Paper, Stack, Typography } from "@mui/material";
import {
  Card,
  LatestReport,
  useLanguageTranslator,
} from "../../../components";
import {
  usePoliciesDocumentsCall,
  usePublicPoliciesCall,
} from "../../../hooks";

function Policies() {
  const { policyDocuments } = usePoliciesDocumentsCall({
    variables: { limit: 5 },
  });
  const { translator } = useLanguageTranslator();

  const {
    policies = [],
    loading,
    refetch,
  } = usePublicPoliciesCall({
    variables: {
      limit: 4,
    },
  });

  return (
    <>
      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3, mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">{translator("Policies")}</Typography>
            <Link href="/policies/all" underline="none" color="textSecondary">
              {translator("View all")}
            </Link>
          </Stack>
        </Paper>

        <Grid container spacing={2}>
          {policies?.map((elem: any, i: any) => (
            <Grid key={`${i}`} item xs={12} md={6} lg={3}>
              <Card
                status={elem?.status}
                path={`/policies/${elem?.id}`}
                title={elem?.title}
                description={elem?.description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>

      <LatestReport items={policyDocuments} path="/policies/viewAllReports" />
    </>
  );
}

export { Policies };
