import React from "react";
import { PracticeInputData } from "../../@lib/types";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Stepper, { Step } from "../../components/@common/Stepper";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import PracticeInfoForm from "./PracticeInfoForm";
import PoliciesInfoForm from "./PoliciesInfoForm";
import PeopleInfoForm from "./PeopleInfoForm";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as PolicyIcon } from "../../@lib/icons/policy-icon.svg";
import { ReactComponent as PracticesIcon } from "../../@lib/icons/practices-icon.svg";
import { ReactComponent as PersonIcon } from "../../@lib/icons/person-icon.svg";
import { FormProvider } from "react-hook-form";

import { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { gql } from "@apollo/client";
import { useApolloQuery } from "../../hooks";

export type PracticeEditorProps = {
  initialValues?: PracticeInputData;
  disabled?: boolean;
  onCancel?: () => void;
  onFinish?: (practiceData: PracticeInputData) => void;
};

const practiceFormOptionsQueryDocument = gql`
  query PracticeFormOptionsQuery {
    categoryOptions: practiceCategories {
      value: id
      label: name
      practiceTypeOptions: practiceTypes {
        value: id
        label: name
      }
    }
    countryOptions: countries {
      value: id
      label: name
    }
    themeOptions: themes {
      value: id
      label: title
    }
    policyOptions: policies {
      value: id
      label: title
    }
  }
`;

const STEPS: Step[] = [
  {
    label: "Practice Information",
    icon: <SvgIcon component={PracticesIcon} inheritViewBox />,
  },
  {
    label: "Policies",
    icon: <SvgIcon component={PolicyIcon} inheritViewBox />,
  },
  { label: "People", icon: <SvgIcon component={PersonIcon} inheritViewBox /> },
];

const PracticeEditor: React.FC<PracticeEditorProps> = (props) => {
  const {
    initialValues,
    disabled,
    onCancel = () => void 0,
    onFinish = () => void 0,
  } = props;

  const { data, loading } = useApolloQuery({
    queryDocument: practiceFormOptionsQueryDocument,
  });

  const form = useForm<PracticeInputData>({
    defaultValues: {
      themeId: "",
      countryId: "",
      practiceCategoryId: "",
      practiceTypeId: "",
      policyId: "",
    },
    values: initialValues,
  });

  const totalSteps = STEPS.length;
  const [currStepIndex, setCurrStepIndex] = useState(0);

  const isFirstStep = currStepIndex === 0;
  const isLastStep = currStepIndex + 1 === totalSteps;

  /**
   * handleBack()
   */
  const handleBack = useCallback(
    () => setCurrStepIndex((currVal) => --currVal),
    []
  );

  /**
   * handleContinue()
   */
  const handleContinue = useCallback(async () => {
    const isFormValid = await (() => {
      switch (currStepIndex) {
        case 0:
        case 1:
        case 2:
        default:
          return form.trigger();
      }
    })();

    if (isFormValid) setCurrStepIndex((currVal) => ++currVal);
    else void 0; // TODO: Show notification.
  }, [currStepIndex, form]);

  return (
    <div>
      <Container maxWidth="sm">
        <Stepper steps={STEPS} activeStep={currStepIndex} />
      </Container>

      <Divider sx={{ m: 4, opacity: 0.5 }} />

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onFinish)}>
          {currStepIndex === 0 && (
            <PracticeInfoForm
              countryOptions={data?.countryOptions || []}
              themeOptions={data?.themeOptions || []}
              categoryOptions={data?.categoryOptions || []}
              disabled={loading || disabled}
            />
          )}
          {currStepIndex === 1 && (
            <PoliciesInfoForm
              policyOptions={data?.policyOptions || []}
              disabled={loading || disabled}
            />
          )}
          {currStepIndex === 2 && (
            <PeopleInfoForm disabled={loading || disabled} />
          )}

          <Stack direction="row" justifyContent="flex-end" gap={2} mt={3}>
            {isFirstStep && (
              <Button
                variant="contained"
                color="inherit"
                onClick={onCancel}
                disabled={loading || disabled}
                sx={{ px: 5 }}
              >
                Cancel
              </Button>
            )}
            {!isFirstStep && (
              <Button
                variant="contained"
                color="inherit"
                onClick={handleBack}
                disabled={loading || disabled}
                sx={{ px: 5 }}
              >
                Back
              </Button>
            )}
            {!isLastStep && (
              <Button
                variant="contained"
                onClick={handleContinue}
                disabled={loading || disabled}
                sx={{ px: 5 }}
              >
                Continue
              </Button>
            )}
            {isLastStep && (
              <Button
                type="submit"
                variant="contained"
                disabled={loading || disabled}
                sx={{ px: 5 }}
              >
                Finish
              </Button>
            )}
          </Stack>
        </form>
      </FormProvider>
    </div>
  );
};

export { PracticeEditor };
