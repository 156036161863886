import React, { useState } from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink, useLocation, useSearchParams } from "react-router-dom"; // Assuming you're using react-router
import { ConditionalRenderer } from "../../conditionalRenderer";
import { useLanguageTranslator } from "../../useLanguageContext";
import { DownArrowIcon } from "../../../@lib/icons";

const MySpaceMenu = ({ items = [], title = "", icon, isAdmin }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [active, setActive] = useState("");

  const [searchParams] = useSearchParams();
  const location = useLocation();

  const open = Boolean(anchorEl);

  const { translator } = useLanguageTranslator();

  const handleClick = (event: React.MouseEvent<HTMLElement>, key: string) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setActive(anchorEl ? "" : key);
  };

  const isModule = Number(searchParams.getAll("by")?.[0] || "");

  return (
    <>
      <ListItemButton
        onClick={(event) => handleClick(event, title)}
        sx={{
          "&.active": {
            borderColor: "#F6A000",
            borderLeft: 4,
          },
          borderLeft: 4,
          borderColor: active === title ? "#F6A000" : "transparent",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ConditionalRenderer condition={isAdmin}>
              <ListItemIcon sx={{ minWidth: 37 }}>
                <div
                  style={{
                    position: "absolute",
                    left: 22,
                    top: 0,
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    transition: "transform 0.3s ease",
                    transform: open ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  <DownArrowIcon />
                </div>
              </ListItemIcon>
            </ConditionalRenderer>
            <ListItemIcon sx={{ minWidth: 37, marginLeft: "5px" }}>
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={translator(title)}
              primaryTypographyProps={{
                fontWeight: 500,
              }}
            />
          </div>
          <ConditionalRenderer condition={!isAdmin}>
            <div
              style={{
                position: "absolute",
                right: 13,
                top: 0,
                display: "flex",
                alignItems: "center",
                height: "100%",
                transition: "transform 0.3s ease",
                transform: open ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              <DownArrowIcon />
            </div>
          </ConditionalRenderer>
        </div>
      </ListItemButton>
      <ConditionalRenderer condition={open}>
        {items.map((item: any, i: any) => (
          <ListItemButton
            key={`mySubmission-${i}`}
            component={NavLink}
            to={item.path}
            sx={{
              borderLeft: item?.id ? 0 : 4,
              borderColor: "transparent",
              marginLeft: "30px",
              "&.active": {
                borderColor: "#F6A000",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <ConditionalRenderer condition={item?.icon}>
                {item.icon}
              </ConditionalRenderer>
              <ConditionalRenderer condition={!item?.icon}>
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor:
                      isModule === item?.id || location?.pathname === item?.path
                        ? "#F6A000"
                        : "black",
                    borderRadius: "100%",
                    marginLeft: "8px",
                  }}
                />
              </ConditionalRenderer>
            </ListItemIcon>
            <ListItemText
              primary={item.label}
              primaryTypographyProps={{
                fontWeight: 500,
              }}
            />
          </ListItemButton>
        ))}
      </ConditionalRenderer>
    </>
  );
};

export default MySpaceMenu;
