import type { ForwardRefRenderFunction } from "react";
import Select, { SelectProps } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

import { forwardRef } from "react";
import { useThemeOptions } from "../../hooks";

export type ThemesDropdownProps = SelectProps & {};

const ThemesDropdownComp: ForwardRefRenderFunction<
  HTMLElement,
  ThemesDropdownProps
> = (props, ref) => {
  const { children, ...selectProps } = props;

  const [options, loading] = useThemeOptions();

  return (
    <Select
      ref={ref}
      displayEmpty={!loading}
      startAdornment={
        loading && (
          <InputAdornment position="start">
            <CircularProgress size={20} />
          </InputAdornment>
        )
      }
      {...selectProps}
    >
      <MenuItem value="" disabled>
        Select theme
      </MenuItem>
      {options.map((op: any, i: any) => (
        <MenuItem key={`${op.value}-${i}`} value={op.value}>
          {op.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const ThemesDropdown = forwardRef(ThemesDropdownComp);

export { ThemesDropdown };
