import React from "react";
import { useCallback } from "react";
import { useMutation, gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Box, Container, Stack, Paper } from "@mui/material";
import { AppBar, PageTitle, SearchBar } from "../../../components";
import { PolicyInputData, PolicyEditor } from "../../../features";

import { useNotify } from "../../../components/@common/NotificationsProvider";

const createPolicyMutationDocument = gql`
  mutation createPolicyPutation($policyInputData: PolicyCreateInput!) {
    policyCreate(input: $policyInputData) {
      policy {
        id
      }
    }
  }
`;

const PolicyNew: React.FC = () => {
  const notify = useNotify();
  const navigate = useNavigate();
  const [createPolicy, { loading }] = useMutation(createPolicyMutationDocument);

  /**
   * handleFinish()
   */
  const handleFinish = useCallback(
    async (data: PolicyInputData) => {
      try {
        await createPolicy({
          variables: {
            policyInputData: {
              policyInput: data,
            },
          },
        });

        navigate("/policies", { replace: true });
      } catch (err) {
        notify.error(err as Error);
      }
    },
    [navigate, createPolicy, notify]
  );

  /**
   * handleCancel()
   */
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box mb={24}>
      <AppBar>
        <Stack
          direction="row"
          flexGrow={1}
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <PageTitle
            title="Create new policies"
            subtitle="Lorem ipsum dolor sit amet."
          />

          <SearchBar placeholder="Search tree and keyword" />
        </Stack>
      </AppBar>

      <Container sx={{ my: 3 }}>
        <Paper component={Container} sx={{ py: 3 }}>
          <PolicyEditor
            disabled={loading}
            onFinish={handleFinish}
            onCancel={handleCancel}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export { PolicyNew };
